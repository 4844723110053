import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { Template } from '../../models/template';
import { DataService } from '@app/services/data.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

// logger //
import { Logger } from '@app/services/logger.service';
const log = new Logger('TemplateDataSource');

export class TemplateDataSource implements DataSource<Template> {

  private dataSubject = new BehaviorSubject<Template[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataChange: BehaviorSubject<Template[]> = new BehaviorSubject<Template[]>([]);
  get data(): Template[] { return this.dataChange.value; }
  public template: Template;
  items: any;

  constructor(
    private service: DataService
  ) {}

    connect(collectionViewer: CollectionViewer): Observable<Template[]> {
      return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.dataSubject.complete();
      this.loadingSubject.complete();
    }

  getChannels(query: any) {
      this.loadingSubject.next(true);
      this.service
        .getTemplate(query)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(data => {
          this.template = data;
          this.items = data.content.channels;
          return this.dataSubject.next(data.content.channels);
        });
    }
}
