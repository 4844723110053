export const environment: any = {
  production: false
};

export class Configuration {

  // Supported languages
  public static GET_SUPPORTED_LANGUAGES() {
    return [
      { code: 'en', title: 'English', icon: '167794 - gb.png' },
      { code: 'de', title: 'German',  icon: '167808 - de.png' }
    ];
  }

  // Application locale used for datetime input formats
  // Unicode locale identifiers) based on the norm BCP47
  public static GET_APP_LOCALE() {
    return 'ru-RU';
  }

  // Datetime custom native formats
  public static GET_PBI_CREDENTIALS() {
    return {
      grant_type: 'password',
      scope: 'openid',
      resource: 'https://analysis.windows.net/powerbi/api',
      client_id: '9f4ebb28-6d3f-493f-958f-cf5524be4182',
      username: 'alexey.horoshavin@grse.de',
      password: 'Powerbi3342'
    };
  }

  // Datetime custom native formats
  public static GET_DATETIME_FORMATS() {
    return {
      fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
      datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
      timePickerInput: {hour: 'numeric', minute: 'numeric'},
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
    };
  }

  public static GET_APP_DATETIME_CUMSTOM_LOCALE() {
    return {
      /** A label for the up second button (used by screen readers).  */
      upSecondLabel: 'Добавить секунду',
      /** A label for the down second button (used by screen readers).  */
      downSecondLabel: 'Удалить секунду',
      /** A label for the up minute button (used by screen readers).  */
      upMinuteLabel: 'Добавить минуту',
      /** A label for the down minute button (used by screen readers).  */
      downMinuteLabel: 'Удалить минуту',
      /** A label for the up hour button (used by screen readers).  */
      upHourLabel: 'Добавить час',
      /** A label for the down hour button (used by screen readers).  */
      downHourLabel: 'Удалить час',
      /** A label for the previous month button (used by screen readers). */
      prevMonthLabel: 'Предыдущий месяц',
      /** A label for the next month button (used by screen readers). */
      nextMonthLabel: 'Следующий месяц',
      /** A label for the previous year button (used by screen readers). */
      prevYearLabel: 'Предыдущий год',
      /** A label for the next year button (used by screen readers). */
      nextYearLabel: 'Следующий год',
      /** A label for the previous multi-year button (used by screen readers). */
      prevMultiYearLabel: 'Previous 21 years',
      /** A label for the next multi-year button (used by screen readers). */
      nextMultiYearLabel: 'Next 21 years',
      /** A label for the 'switch to month view' button (used by screen readers). */
      switchToMonthViewLabel: 'Просмотр месяца',
      /** A label for the 'switch to year view' button (used by screen readers). */
      switchToMultiYearViewLabel: 'Выберите месяц и год',
      /** A label for the cancel button */
      cancelBtnLabel: 'Отмена',
      /** A label for the set button */
      setBtnLabel: 'Выбрать',
      /** A label for the range 'from' in picker info */
      rangeFromLabe: 'От',
      /** A label for the range 'to' in picker info */
      rangeToLabel: 'До',
      /** A label for the hour12 button (AM) */
      hour12AMLabel: 'AM',
      /** A label for the hour12 button (PM) */
      hour12PMLabel: 'PM',
    };
  }
}
