import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// libs
import { Store } from '@ngrx/store';
import {Subject, Observable, Subscription} from 'rxjs';

// store
import * as fromRoot from '@app/modules/store/app.reducers';
import * as layout from '@app/framework/actions/layout';
import * as toolbar from '@app/framework/actions/toolbar';

// animations
import { ToolbarAnimation } from '@app/framework/animations/animations';

// services
import { DataService } from '@app/services/data.service';
import { MsalService} from '@azure/msal-angular';
import { BroadcastService } from '@azure/msal-angular';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('ToolbarComponent');

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [ToolbarAnimation]
})
export class ToolbarComponent implements OnInit, OnDestroy {

  @Input() isMobile: boolean;
  showSideNav: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userInfo: any;

  // broadcastService subscription
  private subscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private dataService: DataService,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userInfo = this.authService.getUser();
    // log.debug(`###getUserDetails data`, this.userInfo);
    this.broadcastService
      .subscribe('msal:loginSuccess', () => {
      this.userInfo = this.authService.getUser();
    });
    this.store.select(fromRoot.getShowSidenav)
      .takeUntil(this.destroy$)
      .subscribe((data: any) => {
        this.showSideNav = data;
      });
  }

  toggle(): void {
    this.showSideNav ? this.store.dispatch(new layout.CloseSidenav()) : this.store.dispatch(new layout.OpenSidenav());
  }

  logout() {
    // logout from azure for all accounts
    // this.authService.logout();
    // clear local storage
    this.store.dispatch(new toolbar.CloseToolbar());
    this.store.dispatch(new layout.CloseSidenav());
    localStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 300);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.broadcastService.getMSALSubject().next(1);
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
