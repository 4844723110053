import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, format, isValid } from 'date-fns';
// import { zonedTimeToUtc } from 'date-fns-tz';
// import * as ruLocale from 'date-fns/locale/ru';
@Pipe({ name: 'appDateFormatPipe' })
@Injectable()
export class DateFormatPipe implements PipeTransform {
    transform(timestamp: any, fmt: string): string {

      const getUTCDate = (date) => {
        return new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds(),
        );
      };

      const _timestamp = timestamp / 1000;
      const localDate = fromUnixTime(_timestamp);
      const utcDate = getUTCDate(localDate);
      return isValid(utcDate) ? format(utcDate, fmt, {}) : 'Invalid date';
    }
}

