import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesComponent } from './devices.component';
import { DashboardRoutingModule } from './devices-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { DevicesDialogService } from './devices-dialog.service';
import { DeviceCreateComponent } from './components/device-create/device-create.component';
import { DeviceSetPropertyComponent } from './components/device-set-property/device-set-property.component';

import {
  MatTabsModule,
  MatTableModule,
  MatInputModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatToolbarModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatFormFieldModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    // additional material components
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatFormFieldModule
  ],
  declarations: [
    DevicesComponent,
    DeviceCreateComponent,
    DeviceSetPropertyComponent
  ],
  exports: [
    DevicesComponent,
    DeviceCreateComponent,
    DeviceSetPropertyComponent
  ],
  entryComponents: [
    DeviceCreateComponent,
    DeviceSetPropertyComponent
  ],
  providers: [
    DevicesDialogService
  ],
})
export class DevicesModule { }
