import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './templates.component';
import { TemplateComponent } from './components/template/template.component';
import { ChannelActionsComponent } from './components/channel-actions/channel-actions.component';
import { ChannelComponent } from './components/channel/channel.component';
import { TemplatesDialogService } from './templates-dialog.service';
import { TemplatesRoutingModule } from './templates-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';

import {
  MatTabsModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatToolbarModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSortModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    TemplatesRoutingModule,
    SharedModule,
    // additional material components //
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSortModule
  ],
  declarations: [
    TemplatesComponent,
    TemplateComponent,
    ChannelComponent,
    ChannelActionsComponent
  ],
  entryComponents: [
    ChannelComponent,
    ChannelActionsComponent
  ],
  exports: [
    ChannelComponent,
    ChannelActionsComponent
  ],
  providers: [
    TemplatesDialogService
  ]
})
export class TemplatesModule { }
