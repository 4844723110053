import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DataService {

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient
  ) {
  }

  // iframe auth
  login(username: string, password: string) {
    return this.http.post<any>(`http://80.151.18.113:9090`, { username, password })
      .pipe(map(user => {
        if (user) {
          user.authdata = window.btoa(username + ':' + password);
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

  // PBI methods
  dataPush(params: any): any {
    const url = `${environment.apiUrl}/api/datapush`;
    return this.http.post(url, params);
  }

  // API methods
  getUserReports(): any {
    const url = `${environment.apiUrl}/api/powerbi/groupsreportsdashboards`;
    return this.http.get(url, {});
  }

  getUserDetails(): any {
    const url = `${environment.apiUrl}/api/users/userinfo`;
    return this.http.get(url, {});
  }

  // all wagons whole history
  getAllWagonsHistory(): any {
    const url = `${environment.apiUrl}/api/wagon/history`;
    return this.http.get(url, {});
  }

  // single wagon whole history
  getSingleWagonHistory(id: number): any {
    const url = `${environment.apiUrl}/api/wagon/history/${id}`;
    return this.http.get(url, {});
  }

  // all wagons whole history top N
  getAllWagonsHistoryTopN(limit: number): any {
    const url = `${environment.apiUrl}/api/wagon/history/top/${limit}`;
    return this.http.get(url, {});
  }

  // single wagon whole history top N
  getSingleWagonHistoryTopN(id: number, limit: number): any {
    const url = `${environment.apiUrl}/api/wagon/history/${id}/top/${limit}`;
    return this.http.get(url, {});
  }

  // dashboard
  getDevicesStatuses(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/laststates`;
    return this.http.get(url, { params: params });
  }

  getDeviceDashboardStatuses(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/lastactivity`;
    return this.http.get(url, { params: params });
  }

  deviceDashboardFixError(data: any): any {
    const url = `${environment.apiUrl}/api/devices/state`;
    return this.http.patch(url, data);
  }

  // all devices
  getDevices(params: any): any {
    const url = `${environment.apiUrl}/api/azureiot/devices`;
    return this.http.get(url, { params: params });
  }

  createDevice(data: any): any {
    const url = `${environment.apiUrl}/api/azureiot/devices`;
    return this.http.post(url, data);
  }

  updateDevice(id: any, data: any): any {
    const url = `${environment.apiUrl}/api/azureiot/device?id=${id}`;
    return this.http.patch(url, data);
  }

  updateDevices(data: any): any {
    const url = `${environment.apiUrl}/api/azureiot/devices`;
    return this.http.patch(url, data);
  }

  getDevice(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/lastactivity`;
    return this.http.get(url, { params: params });
  }

  streamingAuth(streamingUrl: any, authorizationData: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(authorizationData)
      })};
    return this.http.get(streamingUrl, httpOptions);
  }

  // charts
  getChannelData(deviceId: string, channel: any, params: any): any {
    const url = `${environment.apiUrl}/api/devices/${deviceId}/${channel.resource}/${channel.id}`;
    return this.http.get(url, { params: params });
  }

  getChartsData(deviceId: string, params: any): any {
    const url = `${environment.apiUrl}/api/devices/${deviceId}/channels`;
    return this.http.get(url, { params: params });
  }

  // all templates
  getTemplates(params: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates`;
    return this.http.get(url, { params: params });
  }

  getTemplate(params: any): any {
    const url = `${environment.apiUrl }/api/devicetemplates/template`;
    return this.http.get(url, { params: params });
  }

  updateTemplate(id: any, data: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates/${id}`;
    return this.http.patch(url, data);
  }

  // locations
  getDeviceLocations(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/locations`;
    return this.http.get(url, { params: params });
  }

  getFreightCarLocations(): any {
    const url = `${environment.apiUrl}/api/freightcar/locations`;
    return this.http.get(url, {});
  }

  getSingleFreightCarLocations(id: any): any {
    const url = `${environment.apiUrl}/api/freightcar/locations/${id}?pageNumber=1&pageSize=1`;
    return this.http.get(url, {});
  }

  sendTelemetry(count: number, interval: number): any {
    const url = `${environment.apiUrl}/api/freightcar/sendtelemetry?count=${count}&interval=${interval}`;
    return this.http.post(url, {});
  }

  createAction(data: any, templateId: any, channelId: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates/${templateId}/channel/${channelId}/action`;
    return this.http.post(url, data);
  }

  updateAction(data: any, templateId: any, actionId: any, channelId: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates/${templateId}/channel/${channelId}/actions/${actionId}`;
    return this.http.patch(url, data);
  }

  // all devices
  getTemplateaActions(templateId: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates/${templateId}/actions`;
    return this.http.get(url, {});
  }

  deleteAction(templateId: any, actionId: any): any {
    const url = `${environment.apiUrl}/api/devicetemplates/${templateId}/actions/${actionId}`;
    return this.http.delete(url, {});
  }

  // states
  getLastActivityOfDevice(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/lastactivityondevicebyid`;
    return this.http.get(url, { params: params });
  }

  getDataForChartOfDevice(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/lastactivityondevicechannelseries`;
    return this.http.get(url, { params: params });
  }

  getChannelsOfDevice(params: any): any {
    const url = `${environment.apiUrl}/api/devices/channels`;
    return this.http.get(url, { params: params });
  }

  getListOfDevices(params: any): any {
    const url = `${environment.apiUrl}/api/installationpoint/devices`;
    return this.http.get(url, { params: params });
  }


}
