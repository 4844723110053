import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DeviceDashboardComponent } from './components/device-dashboard/device-dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { DatetimeSettingsComponent } from './components/device-dashboard/datetime-settings/datetime-settings.component';
import { StatesComponent } from './components/device-dashboard/states/states.component';
import { ChartComponent } from './components/device-dashboard/states/chart/chart.component';
import { LastStatesComponent } from './components/device-dashboard/states/laststates/last-states.component';

import {
  MatTabsModule,
  MatTableModule,
  MatInputModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonModule,
  MatToolbarModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatFormFieldModule
} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    // additional material components
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatFormFieldModule
  ],
  declarations: [
    DashboardComponent,
    DeviceDashboardComponent,
    DatetimeSettingsComponent,
    StatesComponent,
    ChartComponent,
    LastStatesComponent,
  ],
  exports: [
    DashboardComponent,
    DeviceDashboardComponent,
  ]
})
export class DashboardModule { }
