import { Observable , Subject} from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import './operators';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
  ViewChild, enableProdMode
} from '@angular/core';

import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { isPlatformBrowser, Location } from '@angular/common';
import { MatSidenav } from '@angular/material';
import { links } from './nav-links';
import { environment } from '@env/environment';
import { BroadcastService } from '@azure/msal-angular';
import { MsalService} from '@azure/msal-angular';
import { Subscription } from 'rxjs/Subscription';

// redux
import * as fromRoot from './modules/store/app.reducers';
import * as layout from './framework/actions/layout';
import * as toolbar from '@app/framework/actions/toolbar';
import { ActionsSubject } from '@ngrx/store';

// animations
import { ToolbarAnimation } from './framework/animations/animations';

// logger
import { Logger } from './services/logger.service';
const log = new Logger('AppComponent');

// services
import { DataService } from '@app/services/data.service';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ToolbarAnimation],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  isBrowser: boolean;
  links = links;
  mobileQuery: MediaQueryList;
  _mobileQueryListener: () => void;
  destroy$: Subject<boolean> = new Subject<boolean>();

  // redux
  activeRoute: any;
  showSidenav$: Observable<boolean>;
  showToolbar$: Observable<string>;

  // auth
  loggedIn: boolean;
  public userInfo: any = null;
  private subscription: Subscription;
  public isIframe: boolean;

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private store: Store<fromRoot.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private actionsSubject: ActionsSubject,
    private cdRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dataService: DataService,
    private broadcastService: BroadcastService,
    private authService: MsalService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.showSidenav$ = this.store.select(fromRoot.getShowSidenav);
    this.showToolbar$ = this.store.select(fromRoot.getShowToolbar);
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
    if (this.authService.getUser()) {
      this.loggedIn = true;
      this.expandNavigation();
      if (window.location.pathname === '/login') {
        this.router.navigate(['/']);
      }
    } else {
      this.loggedIn = false;
    }
  }

  ngOnInit(): void {
    if (environment.production) {
      enableProdMode();
    }
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.broadcastService.subscribe('msal:loginFailure', (payload) => {
      log.debug('login failure ' + JSON.stringify(payload));
      this.loggedIn = false;
      this.store.dispatch(new toolbar.CloseToolbar());
      this.store.dispatch(new layout.CloseSidenav());
      localStorage.clear();
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 300);
    });

    // used for toolbar and sidenav opening after success login event
    this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      log.debug('login success ' + JSON.stringify(payload));
      this.loggedIn = true;
      if (window.location.pathname === '/login') {
        this.router.navigate(['/']);
      }
    });
  }

  expandNavigation(): void {
    log.debug('expandNavigation');
    this.store.dispatch(new toolbar.OpenToolbar());
    setTimeout(() => {
      if (!this.mobileQuery.matches) {
        this.store.dispatch(new layout.OpenSidenav());
      }
    }, 800);
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeSidenav() {
    this.store.dispatch(new layout.CloseSidenav());
  }

  onResized(event: ResizedEvent) {
    log.debug('onResized');
    // if (this.mobileQuery.matches) {
    //   this.store.dispatch(new layout.CloseSidenav());
    // } else {
    //   this.store.dispatch(new layout.OpenSidenav());
    // }
  }


}
