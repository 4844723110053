import {
  AfterViewInit,
  Component,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';

import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, FormArray, ValidationErrors, Validators } from '@angular/forms';
import { environment } from '@env/environment';

// libs
import { Store } from '@ngrx/store';
import { Subject ,  Observable , empty, of } from 'rxjs';
import * as format from 'date-fns/format/index';
import * as _ from 'lodash';
import { EmptyObservable } from 'rxjs/observable/EmptyObservable';
import { catchError, finalize, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('ChannelActionsComponent');

// services
import { DataService } from '@app/services/data.service';
import { UtilsService } from '@app/services/utils.service';

@Component({
  selector: 'app-channel-actions',
  templateUrl: 'channel-actions.component.html',
  styleUrls: ['channel-actions.component.scss']
})
export class ChannelActionsComponent implements AfterViewInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  ready = false;
  form: FormGroup;
  deviceTemplates: Array<any>;
  error: any;
  templateId: any;
  channels: any;
  selectedChannel: any;
  selectedChannelType: any;
  selectedAction: any;

  // questions array inputs
  actionTypes: Array<any> = ['email', 'sms'];
  selectedType: string;

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ChannelActionsComponent>,
    public fb: FormBuilder,
    private utilsService: UtilsService,
  ) {
    this.form = this.fb.group({
      channel: ['', Validators.required],
      level: [undefined],
      message: [''],
      actionType: ['', Validators.required],
      actionName: ['', Validators.required],
      action: this.fb.group({}),
    });
  }

  get actionControl(): any { return this.form.get('action'); }
  get actionControlArray() {
    return this.form.get('action') as FormArray;
  }

  ngAfterViewInit() {
    this.loading = true;
    this.cdRef.detectChanges();
    this.dataService.getTemplates({})
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loading = false;
        }),
      )
      .takeUntil(this.destroy$)
      .subscribe(data => {
        this.deviceTemplates = data;
        log.debug('this.selectedAction', this.selectedAction);
        if (this.selectedAction) {
          // this.utilsService.applyFormValues(this.form, this.selectedAction.action);
          // this.form.controls['actionId'].setValue(this.selectedAction.action.actionId);
          this.selectedType = this.selectedAction.action.actionType;
          this.form.controls['actionName'].setValue(this.selectedAction.action.actionName);
          this.form.controls['actionType'].setValue(this.selectedAction.action.actionType);
          this.form.controls['channel'].setValue(this.selectedAction.channel.channelId);
          this.form.controls['channel'].disable();
          this.form.controls['level'].setValue(this.selectedAction.action.level);
          this.form.controls['message'].setValue(this.selectedAction.action.message);
          this.selectedChannelType = this.selectedAction.channel.dataType;
          if (this.selectedAction.action.actionType === 'sms') {
            log.debug('add sms array');
            this.actionControl.addControl('to', new FormControl(this.selectedAction.action.action.to, Validators.required));
            this.actionControl.addControl('text', new FormControl(this.selectedAction.action.action.text, Validators.required));
          } else if (this.selectedAction.action.actionType === 'email') {
            log.debug('add email array');
            this.actionControl.addControl('body', new FormControl(this.selectedAction.action.action.body, Validators.required));
            this.actionControl.addControl('subject', new FormControl(this.selectedAction.action.action.subject, Validators.required));
            this.actionControl.addControl('to', new FormControl(this.selectedAction.action.action.to, Validators.required));
          }
          this.loading = false;
          this.ready = true;
          this.cdRef.markForCheck();
        } else if (!this.selectedAction && this.selectedChannel) {
          this.form.controls['channel'].setValue(this.selectedChannel);
          if (this.channels) {
            const index = _.findIndex(this.channels, ['channelID', this.selectedChannel]);
            this.selectedChannelType = this.channels[index].dataType;
          }
          this.cdRef.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.name === i2.name;
  }

  compareChannels(i1, i2) {
    return i1 && i2 && i1 === i2;
  }

  channelTypeTrigger(event): void {
    const index = _.findIndex(this.channels, ['channelID', event.value]);
    this.selectedChannelType = this.channels[index].dataType;
    log.debug('this.selectedChannel', this.selectedChannelType);
    this.cdRef.markForCheck();
  }

  actionTypeTrigger(event): void {
    log.debug('event', event);
    this.actionControlArray.controls = [];
    this.actionControlArray.setValue([]);
    this.selectedType = event.value;
    if (this.selectedType === 'sms') {
      log.debug('add sms array');
      this.actionControl.addControl('to', new FormControl('', Validators.required));
      this.actionControl.addControl('text', new FormControl('', Validators.required));
    } else if (this.selectedType === 'email') {
      log.debug('add email array');
      this.actionControl.addControl('body', new FormControl('', Validators.required));
      this.actionControl.addControl('subject', new FormControl('', Validators.required));
      this.actionControl.addControl('to', new FormControl('', Validators.required));
    }
    this.cdRef.markForCheck();
  }

  submit(): void {
    this.loading = true;
    const action = this.form.value;
    const channel = this.form.value.channel
    delete action.channel;
    log.debug(action);
    if (this.selectedAction) {
      this.dataService.updateAction(action, this.templateId, this.selectedAction.action.actionId, this.selectedAction.channel.channelId)
        .takeUntil(this.destroy$)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.cdRef.markForCheck();
          }),
        )
        .subscribe(
          response => {
            this.dialogRef.close({
              status: 'success',
              message: response.message
            });
          },
          error =>  {
            log.error(error);
          },
        );
    } else {
      this.dataService.createAction(action, this.templateId, channel)
        .takeUntil(this.destroy$)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.cdRef.markForCheck();
          }),
        )
        .subscribe(
          response => {
            this.dialogRef.close({
              status: 'success',
              message: response.message
            });
          },
          error =>  {
            log.error(error);
          },
        );
    }

  }

}
