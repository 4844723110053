import {
  AfterViewInit,
  AfterContentChecked,
  Component,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';

import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, FormArray, ValidationErrors, Validators } from '@angular/forms';
import { environment } from '@env/environment';

// libs
import { Store } from '@ngrx/store';
import { Subject ,  Observable , empty, of } from 'rxjs';
import * as format from 'date-fns/format/index';
import * as _ from 'lodash';
import { EmptyObservable } from 'rxjs/observable/EmptyObservable';
import { catchError, finalize, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('ChannelActionsComponent');

// services
import { DataService } from '@app/services/data.service';
import { UtilsService } from '@app/services/utils.service';

@Component({
  selector: 'app-channel',
  templateUrl: 'channel.component.html',
  styleUrls: ['channel.component.scss']
})
export class ChannelComponent implements AfterViewInit, AfterContentChecked, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  form: FormGroup;
  error: any;
  templateId: any;
  template: any;
  selectedChannel: any;

  dataTypes = [
    { name: 'numeric', title: 'Numeric'},
    { name: 'string', title: 'String'},
    { name: 'boolean', title: 'Boolean'}
  ];

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ChannelComponent>,
    private utilsService: UtilsService,
    public fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      channelID: ['', Validators.required],
      displayName: ['', Validators.required],
      dataType: ['', Validators.required],
      description: [],
      lowCritical: [],
      lowWarning: [],
      highCritical: [],
      highWarning: [],
      normalValue: [],
      warningValue: []
    });
  }

  get actionControl(): any { return this.form.get('action'); }
  get actionControlArray() {
    return this.form.get('action') as FormArray;
  }

  ngAfterViewInit() {
    this.loading = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
        this.dataService
          .getTemplate({id: this.templateId})
          .pipe(
            catchError(() => of([])),
            finalize(() => {
              if (this.selectedChannel) {
                this.utilsService.applyFormValues(this.form, this.selectedChannel);
              }
              this.loading = false;
              this.cdRef.markForCheck();
            }),
          )
          .subscribe(data => {
            this.template = data;
          });
    }, 100);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1 === i2;
  }

  logForm(): void {
    log.debug(this.form);
  }

  submit(): void {
    this.loading = true;
    log.debug(this.form.value);
    if (this.selectedChannel) {
      const index = _.findIndex(this.template.content.channels, ['channelID', this.selectedChannel.channelID]);
      this.template.content.channels[index].displayName = this.form.controls['displayName'].value;
      this.template.content.channels[index].dataType = this.form.controls['dataType'].value;
      this.template.content.channels[index].description = this.form.controls['description'].value;
      this.template.content.channels[index].lowCritical = this.form.controls['lowCritical'].value;
      this.template.content.channels[index].lowWarning = this.form.controls['lowWarning'].value;
      this.template.content.channels[index].highCritical = this.form.controls['highCritical'].value;
      this.template.content.channels[index].highWarning = this.form.controls['highWarning'].value;
      this.template.content.channels[index].normalValue = this.form.controls['normalValue'].value;
      this.template.content.channels[index].warningValue = this.form.controls['warningValue'].value;
    } else {
      const channel = {
        channelID : this.form.controls['channelID'].value,
        displayName : this.form.controls['displayName'].value,
        dataType : this.form.controls['dataType'].value,
        description : this.form.controls['description'].value,
        lowCritical : this.form.controls['lowCritical'].value,
        lowWarning : this.form.controls['lowWarning'].value,
        highCritical : this.form.controls['highCritical'].value,
        highWarning : this.form.controls['highWarning'].value,
        normalValue : this.form.controls['normalValue'].value,
        warningValue : this.form.controls['warningValue'].value
      }
      this.template.content.channels.push(channel);
    }

    this.dataService.updateTemplate(this.templateId, this.template)
      .takeUntil(this.destroy$)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdRef.markForCheck();
        }),
      )
      .subscribe(
        response => {
          this.dialogRef.close({
            status: 'success',
            message: response.message
          });
        },
        error =>  {
          log.error(error);
          this.error = error.error.message;
        },
      );
  }


}
