import {
  AfterViewInit,
  Component,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';

import {MatDialogRef, MatTableDataSource} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {environment} from '@env/environment';

// libs
import {Store} from '@ngrx/store';
import {Subject, Observable, empty, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {map, startWith} from 'rxjs/operators';
import * as format from 'date-fns/format/index';
import * as _ from 'lodash';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';

// logger
import {Logger} from '@app/services/logger.service';

const log = new Logger('InventoryComponent');

// models
import {Device} from '../../models/device';

// services
import {DataService} from '@app/services/data.service';

@Component({
  selector: 'app-device-create',
  templateUrl: 'device-create.component.html',
  styleUrls: ['device-create.component.scss']
})
export class DeviceCreateComponent implements AfterViewInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  loading = false;
  form: FormGroup;
  deviceTemplates: Array<any>;
  error: any;

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DeviceCreateComponent>,
    public fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      deviceId: ['', Validators.required],
      deviceTemplateID: ['', Validators.required],
      deviceDescription: [],
      HardwareId: ['']
    });
  }

  ngAfterViewInit() {
    this.loading = true;
    this.cdRef.detectChanges();
    this.dataService.getTemplates({})
      .takeUntil(this.destroy$)
      .subscribe(
        response => this.deviceTemplates = response,
        error => log.error(error),
        () => {
          this.loading = false;
        }
      );
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit(): void {
    this.loading = true;
    const device = {
      deviceId: this.form.value.deviceId,
      properties: {
        desired: {},
        reported: {}
      },
      installationPointDescription: this.form.value.deviceDescription,
      deviceTemplateId: this.form.value.deviceTemplateID,
    };
    log.debug('device', device);
    this.dataService.createDevice(device)
      .takeUntil(this.destroy$)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.cdRef.markForCheck();
        }),
      )
      .subscribe(
        response => {
          this.dialogRef.close({
            status: 'success',
            message: response.message
          });
        },
        error => {
          log.error(error);
          this.error = error.error.message;
        },
      );
  }

}
