import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// env
import { Configuration } from '@conf/env.config';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from 'ng-pick-datetime';
import { NativeDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/native-date-time-adapter.class';
import { environment } from '@env/environment';

// libs
import { RouterStateSerializer } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from '@app/framework/utils/custom-router-state-serializer';
import { ConfigLoader, ConfigModule } from '@ngx-config/core';
import { ConfigHttpLoader } from '@ngx-config/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { MsalModule } from '@azure/msal-angular';
import { MsalInterceptor} from '@azure/msal-angular';
import { LogLevel } from 'msal';

// for AoT compilation
export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(http, './assets/config.local.json');
}

// framework
import { MaterialModule } from './framework/material/material.module';
import { AngularResizedEventModule } from 'angular-resize-event';

// routes & components
import { SharedModule } from './modules/shared/shared.module';
import { DialogModule } from './modules/dialog/dialog.module';
import { LoginModule } from './components/login/login.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { DevicesModule } from './components/devices/devices.module';
import { TemplatesModule } from './components/templates/templates.module';

// feature modules
import { AppStoreModule } from './modules/store/app-store.module';
// import { BasicAuthInterceptor } from '@app/services/basic-auth.interceptor';
import { MultilingualModule, Languages, HttpLoaderFactory } from './modules/i18n/index';

// azure logger
export function loggerCallback(logLevel, message, piiEnabled) {
  console.log('client logging' + message);
}

// resource maps
export const protectedResourceMap: [string, string[]][] = [
  [`${environment.apiUrl}/api/azureiot/devices`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/azureiot/device`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/devices/`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/devices/laststates`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/devices/state`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/devices/channels`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/devicetemplates`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/laststates`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/lastactivity`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/locations`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/lastactivityinchannel`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/lastactivityinchannelseries`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/lastactivityondevicechannelseries`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/installationpoint/devices`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/reports/installationpoint`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/reports/devices`, [`api://${environment.consentScope}/access_as_user`]],
  [`${environment.apiUrl}/api/reports/download`, [`api://${environment.consentScope}/access_as_user`]],
  ['https://graph.microsoft.com/v1.0/me', ['user.read']]
];

@NgModule({
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      PlatformModule,
      AppRoutingModule,
      ConfigModule.forRoot({
        provide: ConfigLoader,
        useFactory: configFactory,
        deps: [HttpClient]
      }),
      SharedModule.forRoot(),
      DialogModule,
      MaterialModule,
      AngularResizedEventModule,
      MultilingualModule.forRoot([{
        provide: TranslateLoader,
        deps: [HttpClient],
        useFactory: (HttpLoaderFactory)
      }]),
      AppStoreModule,
      // components
      DashboardModule,
      DevicesModule,
      TemplatesModule,
      LoginModule.forRoot(),
      MsalModule.forRoot({
          clientID: environment.clientID,
          authority: `https://login.microsoftonline.com/${environment.authority}`,
          validateAuthority: false,
          cacheLocation : 'localStorage',
          postLogoutRedirectUri: `${environment.serverUrl}/login`,
          navigateToLoginRequestUrl: true,
          popUp: false,
          consentScopes: [ 'user.read', `api://${environment.consentScope}/access_as_user`],
          unprotectedResources: [
            'https://www.microsoft.com/en-us/',
            './assets/config.local.json',
            './assets/translate/core/en.json',
            './assets/translate/core/de.json',
            './assets/translate/shared/en.json',
            './assets/translate/shared/de.json'
          ],
          protectedResourceMap: protectedResourceMap,
          logger: loggerCallback,
          correlationId: '1234',
          level: LogLevel.Info,
          piiLoggingEnabled: false
        }
      ),
    ],
    declarations: [
      AppComponent
    ],
    providers: [
      { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
      { provide: Languages, useValue: Configuration.GET_SUPPORTED_LANGUAGES() },
      { provide: OWL_DATE_TIME_FORMATS, useValue: Configuration.GET_DATETIME_FORMATS() },
      // { provide: OWL_DATE_TIME_LOCALE, useValue: Configuration.GET_APP_LOCALE() },
      { provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform] },
      // { provide: OwlDateTimeIntl, useValue: Configuration.GET_APP_DATETIME_CUMSTOM_LOCALE()},
      { provide: 'struct', useValue: window['Struct']() },
      { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
      // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]

})
export class AppModule {
}
