import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { ConfirmationComponent } from './confirmation/confirmationDialog.component';
import { EventFixErrorDialogComponent } from './event-fix-error/eventFixErrorDialog.component';

const defaultDialogConfig = new MatDialogConfig();

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  confirm(title: string, message: string): Observable<boolean> {
      let dialogRef: MatDialogRef<ConfirmationComponent>;
      dialogRef = this.dialog.open(ConfirmationComponent);
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;

      return dialogRef.afterClosed();
  }

  fixError(element: any, readonly: boolean = false): Observable<boolean> {
    const config = {
      disableClose: false,
      autoFocus: false,
      panelClass: 'modal-with-toolbar',
      width: 'auto',
      height: 'auto',
      minWidth: '600px',
      minHeight: 'auto',
      overflow: 'auto',
      position: {
        top: '50px'
      }
    };

    let dialogRef: MatDialogRef<EventFixErrorDialogComponent>;
    dialogRef = this.dialog.open(EventFixErrorDialogComponent, config);
    dialogRef.componentInstance.element = element;
    dialogRef.componentInstance.readonly = readonly;

    return dialogRef.afterClosed();
  }

}
