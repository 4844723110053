import { AfterViewInit, Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { DataService } from '@app/services/data.service';
import { MsalService} from '@azure/msal-angular';

// logger
import { Logger } from '@app/services/logger.service';
import {Subject} from "rxjs";
const log = new Logger('EventFixErrorDialogComponent');

@Component({
    selector: 'app-events-fix-error-dialog',
    templateUrl: 'eventFixErrorDialog.component.html'
})
export class EventFixErrorDialogComponent implements AfterViewInit, OnDestroy {

  element: any;
  comment: any;
  loading = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: any;
  userInfo: any;
  readonly: boolean;

  constructor(
      public dialogRef: MatDialogRef<EventFixErrorDialogComponent>,
      private dataService: DataService,
      private authService : MsalService,
      private cdRef: ChangeDetectorRef,
  ) {
    this.currentDate = new Date();
  }

  ngAfterViewInit() {
    this.userInfo = this.authService.getUser();
    log.debug('response:', this.userInfo);
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit(): void {
    this.loading = true;
    const data = {
      recordId: this.element.recordId,
      fixingState: {
        isFixed: true,
        comment: this.comment,
        userData: this.userInfo
      }
    };
    this.dataService
      .deviceDashboardFixError(data)
      .subscribe(
        (response: any) => {
          log.debug('response:', response);
          this.loading = false;
          this.dialogRef.close(true);
        },
        err => {
          log.debug('err:', err);
        },
        () => {}
      );
  }
}
