import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { Template } from './models/template';
import { DataService } from '@app/services/data.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { MatPaginator } from '@angular/material';

// logger //
import { Logger } from '@app/services/logger.service';
const log = new Logger('TemplatesDataSource');

export class TemplatesDataSource implements DataSource<Template> {

  private dataSubject = new BehaviorSubject<Template[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataChange: BehaviorSubject<Template[]> = new BehaviorSubject<Template[]>([]);
  get data(): Template[] { return this.dataChange.value; }
  public items: Template[];

  constructor(
    private service: DataService
  ) {}

    connect(collectionViewer: CollectionViewer): Observable<Template[]> {
      return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.dataSubject.complete();
      this.loadingSubject.complete();
    }

    loadItems(query: any, paginator: MatPaginator) {
      query.pageNumber = paginator.pageIndex + 1;
      query.pageSize = paginator.pageSize || 5;
      this.loadingSubject.next(true);
      this.service
        .getTemplates(query)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(items => {
          this.items = items;
          log.debug(this.items);
          return this.dataSubject.next(items);
        });
    }
}
