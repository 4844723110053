import {
  Component,
} from '@angular/core';

import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-device-set-property',
  templateUrl: 'device-set-property.component.html',
  styleUrls: ['device-set-property.component.scss']
})
export class DeviceSetPropertyComponent {

  intervalMotion = new FormControl();
  intervalRest = new FormControl();
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DeviceSetPropertyComponent>,
    public fb: FormBuilder,
  ) {
  }

  submit(): void {
    this.loading = false;
    this.dialogRef.close({
      intervalMotion: this.intervalMotion.value,
      intervalRest: this.intervalRest.value,
    });
  }

}
