import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';

// libs
import { Store } from '@ngrx/store';
import { Subject ,  Observable } from 'rxjs';
import { catchError, finalize, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BroadcastService } from '@azure/msal-angular';
import { MsalService} from '@azure/msal-angular';
import { Subscription } from 'rxjs/Subscription';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('LoginComponent');

// models
import { LoginContext } from '@app/framework/interfaces/logincontext';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  pending$: Observable<boolean>;
  loginForm: FormGroup;
  error: string = undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private broadcastService: BroadcastService ,
    private authService: MsalService
  ) {

  }

  ngOnInit(): void {
    // this.broadcastService
    //   .subscribe("msal:loginFailure", (payload) => {
    //   console.log("login failure " + JSON.stringify(payload));
    //   this.error = 'login failure';
    // });
    //
    // this.broadcastService
    //   .subscribe("msal:loginSuccess", (payload) => {
    //   console.log("login success " + JSON.stringify(payload));
    // });
  }

  login() {
    this.authService.loginRedirect(['user.read' , `api://${environment.consentScope}/access_as_user`]);
  }

  logout() {
    this.authService.logout();
  }

}
