import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MatDialog, MatDialogRef } from '@angular/material';

// components
import { DeviceCreateComponent } from './components/device-create/device-create.component';
import { DeviceSetPropertyComponent } from './components/device-set-property/device-set-property.component';

@Injectable()
export class DevicesDialogService {
  constructor(
    private dialog: MatDialog
  ) {}

  // dialog methods
  createDevice(): Observable<any> {
    const config = {
      disableClose: false,
      autoFocus: false,
      panelClass: 'modal-with-toolbar',
      width: 'auto',
      height: 'auto',
      minWidth: '400px',
      minHeight: 'auto',
      overflow: 'auto',
      position: { top: '250px'}
    };

    let dialogRef: MatDialogRef<DeviceCreateComponent>;
    dialogRef = this.dialog.open(DeviceCreateComponent, config);
    // dialogRef.componentInstance.docId = docId;
    // dialogRef.componentInstance.docType = docType;

    return dialogRef.afterClosed();
  }

  setDeviceProperty(): Observable<string> {
    const config = {
      disableClose: true,
      autoFocus: false,
      panelClass: 'modal-with-toolbar',
      width: 'auto',
      height: 'auto',
      minWidth: '400px',
      minHeight: 'auto',
      overflow: 'auto',
      position: { top: '250px'}
    };

    let dialogRef: MatDialogRef<DeviceSetPropertyComponent>;
    dialogRef = this.dialog.open(DeviceSetPropertyComponent, config);

    return dialogRef.afterClosed();
  }


}
