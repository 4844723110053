import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone, ChangeDetectorRef , ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material';
import {Subject, BehaviorSubject, Observable, Subscription, interval} from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {
  format,
  getTime,
  addYears,
  addMonths,
  addWeeks,
  addDays,
  addHours,
  startOfToday,
  endOfToday
} from 'date-fns';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('DevicesComponent');

// services
import { DataService } from '@app/services/data.service';
import { DevicesDataSource } from './devices.datasource';
import { DevicesDialogService } from './devices-dialog.service';
import { MatSnackBar } from '@angular/material';
import { UtilsService } from '@app/services/utils.service';
import { Sort } from '@angular/material/sort';

// models
import { Device } from './models/device';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})

export class DevicesComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource: DevicesDataSource | null;
  displayedColumns = [
    'select',
    'deviceId',
    'deviceTemplateName',
    'installationPointDescription',
    'wagonType',
    // 'deviceDescription',
    'movingInterval',
    'restingInterval',
    'hardwareLastUpdateDateTime',
    'status',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSize = 10;
  pageSizeOptions = [10, 25, 50];
  pageEvent: PageEvent;
  loading: boolean;

  selection = new SelectionModel<Device>(true, []);

  // default query
  query: any = {
    pageNumber: 1,
    pageSize: 10
  };

  progressbarValue = 0;
  curSec = 0;

  // filter
  @ViewChild('devicesFilter') devicesFilter: ElementRef;
  private searchSub$ = new Subject<string>();
  filterValue: any;

  // export
  isReportLoading: boolean;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private devicesDialogService: DevicesDialogService,
    private snackBar: MatSnackBar,
    private utilsService: UtilsService,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.listItems();
    this.searchSub$.pipe(
      debounceTime(400),
      // distinctUntilChanged()
    ).subscribe((filterValue: string) => {
      // this.tableDataSource.filter = filterValue.trim().toLowerCase();
      log.debug('????? query.filter', this.query.filterBy);
      this.listItems();
    });
  }

  applyFilter(filterValue: string) {
    this.searchSub$.next(filterValue);
    this.query.filterBy = filterValue;
    this.query.pageNumber = 1;
    this.query.pageSize = 10;
  }

  clearFilter(): void {
    this.devicesFilter.nativeElement.value = null;
    delete this.query.filterBy;
    this.listItems();
  }

  listItems(): void {
    // this.selection.clear();
    this.dataSource = new DevicesDataSource(this.dataService);
    this.dataSource.loadItems(this.query, this.paginator);
    this.cdRef.markForCheck();
  }

  sortData(event): void {
    if (event.active && event.direction) {
      this.query.orderBy = event.active;
      this.query.orderDirection = event.direction;
    } else {
      delete this.query.orderBy;
      delete this.query.orderDirection;
    }
    this.listItems();
  }

  onPaginateChange(): void {
    this.listItems();
  }

  isAllSelected(): boolean {
    if (!this.dataSource || !this.dataSource.items) { return false; }
    if (this.selection.isEmpty()) { return false; }
    return this.selection.selected.length === this.dataSource.items.length;
  }

  isEntirePageSelected() {
    if (!this.dataSource || !this.dataSource.items) { return false; }
    return this.dataSource.items.every((row) => this.selection.isSelected(row.deviceId));
  }

  masterToggle() {
    log.debug('isEntirePageSelected', this.isEntirePageSelected());
    this.isEntirePageSelected() ?
      this.dataSource.items.forEach(row => this.selection.deselect(row.deviceId)) :
      this.dataSource.items.forEach(row => this.selection.select(row.deviceId));
    // this.cdRef.markForCheck();
  }

  // custom row-check implementation instead of selection.isSelected(row)
  // isRowSelected(row: any): boolean {
  //   return _.findIndex(this.selection.selected, (o) => {
  //     return _.isMatch(o, row);
  //   }) > -1;
  // }

  // isSelected(row) {
  //   for (const s of this.selection.selected) {
  //     if (s['deviceId'] === row.deviceId) {
  //       return true;
  //     }
  //   }
  // }

  logSelection(): void {
    log.debug(this.selection.selected);
  }

  openCreateDeviceModal(): void {
    this.devicesDialogService
      .createDevice()
      .subscribe(
        (data: any) => {
          if (data && data.status === 'success') {
            this.snackBar.open(data.message, 'Success', {
              duration: 2000,
            });
            this.listItems();
          }
        },
        err => {
          this.snackBar.open(err.message, 'Error', {
            duration: 2000,
          });
        },
        () => {}
      );
  }

  setSendingInterval(): void {
    this.devicesDialogService
      .setDeviceProperty()
      .subscribe(
        (data: any) => {
          if (data) {
            this.setDesiredProperties(data);
          }
        }
      );
  }

  startTimer(seconds: number) {
    const timer$ = interval(1000);
    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 0 + sec * 100 / seconds;
      this.curSec = sec;
      this.cdRef.markForCheck();
      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }

  async setDesiredProperties(data: any) {
    // log.debug('data', data);
    // log.debug('this.selection.selected', this.selection.selected);
    if (this.selection.selected.length) {
      this.startTimer(9);
      this.loading = true;
      // operation promise resolver
      const waitFor = (id) => new Promise((resolve) => {
        const document = {
          properties: {
            desired: {
              intervalMotion: data.intervalMotion,
              intervalRest: data.intervalRest
            }
          }
        };
        return this.dataService
          .updateDevice(id, document)
          .toPromise()
          .then(
            (res: any) => resolve(res),
            (err: any) => resolve(err.error));
      });
      // array iterator
      await this.utilsService.asyncForEach(this.selection.selected, async (deviceId: any) => {
        // const index = this.dataSource.items.findIndex(el => el.deviceId === deviceId);
        // const document = this.dataSource.items[index];
        const operationResult: any = await waitFor(deviceId);
      });
      this.selection.clear();
      setTimeout(() => {
        this.loading = false;
        this.listItems();
      }, 10000);
      log.debug('Done');
    }
  }

  updateFirmware(element: any): void {
    log.debug(element);
  }

  getPropertyState(data: any, type: string): void {
    const desired = _.get(data, `properties.desired[${type}]`);
    const reported = _.get(data, `properties.reported[${type}]`);
    let status;
    if (desired && reported && desired === reported) {
      status = reported;
    } else if (desired && reported && desired !== reported) {
      status = `${reported} &#8594; <span class="muted">${desired}</span>`;
    } else if (desired && !reported) {
      status = `? &#8594; <span class="muted">${desired}</span>`;
    } else if (!desired && reported) {
      status = reported;
    } else if (!desired && !reported) {
      status = `?`;
    }
    return status;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // export report
  generateReport(period: string, isMetric?: boolean) {
    this.isReportLoading = true;
    let dateFrom;
    let dateTo;
    switch (period) {
      case 'TODAY':
        dateFrom = addDays(new Date(), -1);
        dateTo = new Date();
        break;
      case 'THISWEEK':
        dateFrom = addWeeks(new Date(), -1);
        dateTo = new Date();
        break;
      case 'LASTTWOWEEKS':
        dateFrom = addWeeks(new Date(), -2);
        dateTo = new Date();
        break;
      case 'LASTMONTH':
        dateFrom = addMonths(new Date(), -1);
        dateTo = new Date();
        break;
      case 'LASTYEAR':
        dateFrom = addYears(new Date(), -1);
        dateTo = new Date();
        break;
    }
    const url = `${environment.apiUrl}/api/reports/devices`;
    const params = {
      ids: this.selection.selected,
      dateFrom: getTime(dateFrom),
      dateTo: getTime(dateTo),
      isMetric: isMetric
    };
    const queryParams: HttpParams = this.utilsService.buildQueryParams(params);
    this.http.get(url, { params: queryParams })
      .subscribe((data: any) => {
        this.downloadReport(data.link);
        this.isReportLoading = false;
        this.cdRef.markForCheck();
      });
  }

  public getFile(path: string, filename: string): Observable<Blob> {
    return this.http
      .get(path, {
        responseType: 'blob'
      });
  }

  downloadReport(link: any): any {
    this.getFile(link, 'report.csv')
      .subscribe(
        fileData => {
          log.debug('#################', fileData);
          if (fileData) {
            FileSaver.saveAs(fileData, 'report.csv');
          }
        },
        error => log.debug(error),
        () => log.debug('complete'));
  }
}
