import { ConfigService } from '@ngx-config/core';

import { ILang } from '../interfaces/ilang';
import { State } from '../../../modules/store/app.reducers';
import * as multilingual from '../actions/index';

// libs
import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent {

  public lang: string;
  public supportedLanguages: Array<ILang>;

  constructor(
    private store: Store<State>,
    private config: ConfigService,
    private cdRef: ChangeDetectorRef,
  ) {
    store.take(1).subscribe((s: any) => {
      this.lang = s && s.i18n ? s.i18n.lang : '';
    });

    setTimeout(() => {
      this.supportedLanguages = this.config.getSettings('i18n.availableLanguages');
      this.cdRef.markForCheck();
    }, 300);
  }

  changeLang(e: any) {
    const lang = e.value;
    this.store.dispatch(new multilingual.ChangeAction(lang));
  }

}
