import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import * as Highcharts from 'highcharts';

import StockModule from 'highcharts/modules/stock';
import MapModule from 'highcharts/modules/map';
import GanttModule from 'highcharts/modules/gantt';
import ExportingModule from 'highcharts/modules/exporting';
import SunsetTheme from 'highcharts/themes/sunset.src.js';

import {Subject, Subscription} from 'rxjs';
// logger
import {Logger} from '@app/services/logger.service';
import {DataService} from '@app/services/data.service';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {TranslateService} from '@ngx-translate/core';
import {Query} from '@app/framework/interfaces/query';

StockModule(Highcharts);
MapModule(Highcharts);
GanttModule(Highcharts);
ExportingModule(Highcharts);
SunsetTheme(Highcharts);

const log = new Logger('ChartComponent');

@Component({
  selector: 'app-chart',
  templateUrl: 'chart.component.html',
  styleUrls: ['./chart.component.scss']
})

export class ChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() deviceId: any;
  @Input() channel: any;
  @Input() dateFrom: any;
  @Input() dateTo: any;
  @Input() channelName: any;
  @Input() updateRate: number = undefined;
  @Input() chartData: any;
  @Input() deviceType: any;
  @Input() mode: any;

  destroy$: Subject<boolean> = new Subject<boolean>();
  chartLoading: boolean;
  loading: boolean;

  chartCallback;
  chart;
  charts = [];
  obj;
  timestamp;
  version;
  json;

  // master chart
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart'; // optional string, defaults to 'chart' stockChart

  updateFromInput = false;
  optFromInput: Highcharts.Options = {
    chart: {
      zoomType: 'x',
      type: 'line',
      resetZoomButton: {
        theme: {
          fill: 'white',
          stroke: 'silver',
        }
      }
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M:%S',
      shared: true,
      split: false,
      enabled: true,
      positioner: function () {
        return {x: 40, y: -10};
      },
      shadow: false,
      borderWidth: 0,
      backgroundColor: '#eeeeee'
    },
    time: {
      useUTC: true
    },
    credits: {
      enabled: false
    },
    title: {
      text: undefined
    },
    yAxis: {
      title: {
        text: null
      },
      showEmpty: true
    },
    series: [],
    colors: ['#D42626', '#229E92', '#06C'],
    colorAxis: {
      visible: false
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      series: {
        showInLegend: true,
        connectNulls: false,
        marker: {
          enabled: false,
          radius: 2,
          states: {
            hover: {
              enabled: true,
              radius: 2.5
            }
          }
        }
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    }
  };

  noDataMessage: any;
  isInit = false;

  data: Array<any>;
  private dataForChartSub: Subscription;
  description;
  total;
  top;
  pageSize;
  info;
  displayingOrder;
  channelId;
  queryForCharts: Query = {};

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.setxAxisSettings();
    this.isInit = true;
    this.chartCallback = chart => {
      this.chart = chart;
      this.chart.showLoading();
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setxAxisSettings();
      this.queryForCharts = {
        instPointId: this.deviceId,
        channelId: this.channelName,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        deviceType: this.deviceType
      };
      this.getChartsData(this.queryForCharts);
      this.updateFromInput = true;
      this.cdRef.markForCheck();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (changes.dateFrom && changes.dateFrom.previousValue) {
        log.debug('changes ', changes);
        if (!this.chart) {
          this.chartCallback = chart => {
            this.chart = chart;
            this.chart.showLoading();
          };
        }
        this.chart.showLoading();
        this.setxAxisSettings();
        this.dateFrom = changes.dateFrom.currentValue;
        this.queryForCharts.dateFrom = this.dateFrom;
        this.getChartsData(this.queryForCharts);
      }
    }, 1000);
  }

  private getChartsData(query: any) {
    this.dataForChartSub = this.dataService
      .getDataForChartOfDevice(query)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          log.debug('charts', this.charts[0]);
          this.cdRef.markForCheck();
        })
      )
      .subscribe(response => {
        this.data = response.data;
        this.displayingOrder = response.displayingOrder;
        this.channelId = response.channelId;
        this.description = response.description;
        this.charts[0] = {
          data: this.data,
          channelId: this.channelId,
          displayingOrder: this.displayingOrder,
          description: this.description,
        };
        this.drawChart(this.charts[0]);
      });
  }

  private drawChart(response: any): void {
    const chartData = response.data.map((channelData) => {
      const _series = channelData.series.map((item) => {
        return [item.name, item.value];
      });
      return {
        name: this.translate.instant(channelData.name),
        data: _series,
        type: 'line',
      };
    });
    if (response.channelId === 'doorOpen') {
      const labels = ['Close', 'Open'];
      let doorStatus = 'Close';
      this.optFromInput.yAxis = {
      title: {
        text: null
      },
      labels: {
        formatter: function() {
          doorStatus = labels[this.pos];
          return doorStatus;
        }
      },
      showEmpty: true
      };
    }
    this.optFromInput.series = chartData;
    if (chartData.length && !chartData[0].data.length) {
      this.noDataMessage = this.chart.renderer.text('No Data available', 120, 150)
        .css({fontSize: '16px'}).add();
    } else if (chartData.length && chartData[0].data.length && this.noDataMessage) {
      this.noDataMessage.hide();
    }
    this.chart.hideLoading();
    this.updateFromInput = true;
    this.cdRef.markForCheck();
  }

  private toggleSeriesColors(name: string) {
    switch (name) {
      case 'mixed':
        this.optFromInput.colors = ['#D42626', '#229E92', '#06C'];
        break;
      case 'red':
        this.optFromInput.colors = ['#E68080', '#D42626', '#AD0000'];
        break;
      case 'green':
        this.optFromInput.colors = ['#67C1B8', '#229E92', '#0D7B6C'];
        break;
      case 'blue':
        this.optFromInput.colors = ['#39F', '#06C', '#036'];
        break;
    }
    this.updateFromInput = true;
  }

  private toggleSeriesType(name: string) {
    this.optFromInput.series.forEach((series) => {
      series.type = name as 'column' | 'scatter' | 'spline' | 'line';
    });
    this.optFromInput.plotOptions.series.marker.enabled = name === 'scatter';
    // nested change - must trigger update
    this.updateFromInput = true;
  }

  private setxAxisSettings() {
    this.optFromInput.xAxis = {
      type: 'datetime',
      offset: 10,
      min: this.dateFrom,
      max: this.dateTo,
      minRange: 5,
      events: {
        afterSetExtremes: (e) => {
          if (typeof e.userMin === 'undefined' && typeof e.userMax === 'undefined') {
            this.chart.update({
              tickPositions: this.chart.tickPositions
            });
          }
        }
      },
      labels: {
        rotation: -45,
        x: 0,
        y: 40,
      },
    };
    this.updateFromInput = true;
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.dataForChartSub) {
      this.dataForChartSub.unsubscribe();
    }
  }

}
