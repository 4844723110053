import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import * as Highcharts from 'highcharts';

import StockModule from 'highcharts/modules/stock';
import MapModule from 'highcharts/modules/map';
import GanttModule from 'highcharts/modules/gantt';
import ExportingModule from 'highcharts/modules/exporting';
import SunsetTheme from 'highcharts/themes/sunset.src.js';
import {Subject, Subscription} from 'rxjs';
// logger
import {Logger} from '@app/services/logger.service';
// services
import {DataService} from '../../../../../services/data.service';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
// models
import {Query} from '@app/framework/interfaces/query';

StockModule(Highcharts);
MapModule(Highcharts);
GanttModule(Highcharts);
ExportingModule(Highcharts);
SunsetTheme(Highcharts);

const log = new Logger('StatesComponent');

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})

export class StatesComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() mode: any;
  @Input() instPointId: string;
  @Input() channels: any;
  @Input() dateFrom: number;
  @Input() dateTo: number;
  @Input() deviceTypeArray: any[];
  @Input() updateRate: number = undefined;

  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean;
  isInit = false;

  lastStates = [];
  data: Array<any>;
  description;
  total;
  top;
  polling: any;
  pageSize;
  info;
  channelsNames = [];
  updateRateDefault = 30000;
  displayingOrder;
  queryForStates: Query = {};
  private lastStatesDataSub: Subscription;
  private channelsOfDeviceSub: Subscription;

  constructor(
    private dataService: DataService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.updateRate = this.updateRateDefault;
  }

  ngOnInit() {
    this.isInit = true;
  }

  private getChannelsOfDevice(query, deviceRoleName: any) {
    query.deviceType = deviceRoleName;
    this.channelsOfDeviceSub = this.dataService
      .getChannelsOfDevice(query)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.cdRef.markForCheck();
        })
      )
      // .takeUntil(this.destroy$)
      .subscribe(response => {
        switch (deviceRoleName) {
          case ('Asset'): {
            if (response.data) {
              this.channelsNames[0] = response;
              this.channelsNames[0].description = 'ASSET_TRACKING';
              this.channelsNames[0].deviceType = 'Asset';
            }
            break;
          }
          case ('LoadCondition'): {
            if (response.data) {
              this.channelsNames[1] = response;
              this.channelsNames[1].description = 'LOAD_CONDITION';
              this.channelsNames[1].deviceType = 'LoadCondition';
            }
            break;
          }
          case ('DoorMonitoring1'): {
            if (response.data) {
              this.channelsNames[2] = response;
              this.channelsNames[2].description = 'DOOR_MON_1';
              this.channelsNames[2].deviceType = 'DoorMonitoring1';
            }
            break;
          }
          case ('DoorMonitoring2'): {
            if (response.data) {
              this.channelsNames[3] = response;
              this.channelsNames[3].description = 'DOOR_MON_2';
              this.channelsNames[3].deviceType = 'DoorMonitoring2';
            }
            break;
          }
        }
      });
  }

  private getLastStatesData(query: any, deviceId: any, deviceRoleName: any) {
    query.top = '1';
    query.deviceId = deviceId;
    this.lastStatesDataSub = this.dataService
      .getLastActivityOfDevice(query)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          log.debug('laststates', this.lastStates);
          this.cdRef.markForCheck();
        })
      )
      .subscribe(response => {
        this.data = response.data;
        this.description = response.description;
        this.total = response.total;
        switch (deviceRoleName) {
          case ('Asset'): {
            this.lastStates[0] = {
              stateDeviceId: deviceId,
              data: this.data,
              description: 'ASSET_TRACKING',
              total: this.total,
              orderNumber: 0
            };
            break;
          }
          case ('LoadCondition'): {
            this.lastStates[1] = {
              stateDeviceId: deviceId,
              data: this.data,
              description: 'LOAD_CONDITION',
              total: this.total,
              orderNumber: 1
            };
            break;
          }
          case ('DoorMonitoring1'): {
            this.lastStates[2] = {
              stateDeviceId: deviceId,
              data: this.data,
              description: 'DOOR_MON_1',
              total: this.total,
              orderNumber: 2
            };
            break;
          }
          case ('DoorMonitoring2'): {
            this.lastStates[3] = {
              stateDeviceId: deviceId,
              data: this.data,
              description: 'DOOR_MON_2',
              total: this.total,
              orderNumber: 3
            };
            break;
          }
        }
      });
  }

  ngOnChanges(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      for (let i = 0; i < this.deviceTypeArray.length; i++) {
        this.getLastStatesData(this.queryForStates, this.deviceTypeArray[i].hardwareId, this.deviceTypeArray[i].deviceRoleName);
        this.getChannelsOfDevice(this.queryForStates, this.deviceTypeArray[i].deviceRoleName);
      }
      this.polling = setInterval(() => {
        for (let i = 0; i < this.deviceTypeArray.length; i++) {
          this.getLastStatesData(this.queryForStates, this.deviceTypeArray[i].hardwareId, this.deviceTypeArray[i].deviceRoleName);
        }
      }, 25000);
      this.cdRef.markForCheck();
    }, 100);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    clearInterval(this.polling);
    if (this.lastStatesDataSub) {
      this.lastStatesDataSub.unsubscribe();
    }
    if (this.channelsOfDeviceSub) {
      this.channelsOfDeviceSub.unsubscribe();
    }
  }

}
