import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone, ChangeDetectorRef , ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material';
import { Subject, BehaviorSubject,  Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('DevicesComponent');

// services //
import { DataService } from '@app/services/data.service';
import { TemplatesDataSource } from './templates.datasource';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})

export class TemplatesComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource: TemplatesDataSource | null;
  displayedColumns = [
    'id',
    'name'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100];
  pageEvent: PageEvent;
  loading: boolean;

  // default query
  query: any = {
    pageNumber: 1,
    pageSize: 5
  };

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.listItems();
    this.getItemsCount();
  }

  getItemsCount(): void {
    this.loading = true;
    this.dataService.getTemplates({})
      .takeUntil(this.destroy$)
      .subscribe(
        response => this.paginator.length = response.length,
        error => log.error(error),
        () => this.loading = false
      );
  }

  listItems(): void {
    this.dataSource = new TemplatesDataSource(this.dataService);
    this.dataSource.loadItems(this.query, this.paginator);
    this.cdRef.markForCheck();
  }

  onPaginateChange(): void {
    this.listItems();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
