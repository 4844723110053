import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subject, Subscription} from 'rxjs';
// logger
import {Logger} from '@app/services/logger.service';

// models
import {LastState} from './models/last-state';

const log = new Logger('LastStatesComponent');

@Component({
  selector: 'app-dashboard-laststates-card',
  templateUrl: 'last-states.component.html',
  styleUrls: ['./last-states.component.scss']
})

export class LastStatesComponent implements OnInit, OnDestroy {

  @Input() channel: any;
  @Input() legendName: any;
  @Input() hardwareId: any;
  @Input() timestamp: any;
  @Input() orderNumber: any;
  @Input() total: any;
  @Input() stateDeviceId: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean;
  breakpoint: number;
  array: Array<any>;
  isInit = false;
  polling: any;
  door;
  date;
  formattedDate;
  lastState: LastState;

  ngOnInit() {
    this.isInit = true;
    if (window.innerWidth <= 900) {
      this.breakpoint = 1;
    } else {
      if (window.innerWidth > 900 && window.innerWidth <= 1430) {
        this.breakpoint = 2;
      } else {
        if (window.innerWidth > 1430) {
          this.breakpoint = 4;
        }
      }
    }
    log.debug('channel', this.channel);
    if (this.total !== 0) {
      this.arrayParsing();
      this.polling = setInterval(() => {
        this.arrayParsing();
      }, 25000);
    }
  }

  private onResize(event) {
    if (window.innerWidth <= 900) {
      this.breakpoint = 1;
    } else {
      if (window.innerWidth > 900 && window.innerWidth <= 1430) {
        this.breakpoint = 2;
      } else {
        if (window.innerWidth > 1430) {
          this.breakpoint = 4;
        }
      }
    }
  }

  private arrayParsing() {
    this.formatDate(new Date(this.timestamp));
    this.createLastState(this.channel);
    this.array = Array.from(Object.keys(this.lastState), k => [`${k}`, this.channel[k]]);
    for (let i = 0; i < this.array.length; i++) {
      switch (this.array[i][0]) {
        case('batteryLevel'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            this.array[i][1] = this.array[i][1] + ' mV';
          }
          this.array[i][2] = 1;
          break;
        case('networkSignalStrength'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            this.array[i][1] = this.array[i][1] + ' dBm';
          }
          this.array[i][2] = 1;
          break;
        case('temperature'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            const celsius = this.array[i][1];
            this.array[i][1] = ((celsius * 9 / 5) + 32).toFixed(1) + ' °F';
          }
          this.array[i][2] = 1;
          break;
        case('accelerometerX'):
          if (!this.array[i][1].minus.toString().includes('m/s²')) {
            if (this.array[i][1].minus === null) {
              this.array[i][1].minus = 'null';
            } else {
              this.array[i][1].minus = this.array[i][1].minus.toFixed(2) + ' m/s²';
            }
          }
          if (!this.array[i][1].plus.toString().includes('m/s²')) {
            if (this.array[i][1].plus === null) {
              this.array[i][1].plus = 'null';
            } else {
              this.array[i][1].plus = this.array[i][1].plus.toFixed(2) + ' m/s²';
            }
          }
          this.array[i][2] = 2;
          break;
        case('accelerometerY'):
          if (!this.array[i][1].minus.toString().includes('m/s²')) {
            if (this.array[i][1].minus === null) {
              this.array[i][1].minus = 'null';
            } else {
              this.array[i][1].minus = this.array[i][1].minus.toFixed(2) + ' m/s²';
            }
          }
          if (!this.array[i][1].plus.toString().includes('m/s²')) {
            if (this.array[i][1].plus === null) {
              this.array[i][1].plus = 'null';
            } else {
              this.array[i][1].plus = this.array[i][1].plus.toFixed(2) + ' m/s²';
            }
          }
          this.array[i][2] = 2;
          break;
        case('accelerometerZ'):
          if (!this.array[i][1].minus.toString().includes('m/s²')) {
            if (this.array[i][1].minus === null) {
              this.array[i][1].minus = 'null';
            } else {
              this.array[i][1].minus = this.array[i][1].minus.toFixed(2) + ' m/s²';
            }
          }
          if (!this.array[i][1].plus.toString().includes('m/s²')) {
            if (this.array[i][1].plus === null) {
              this.array[i][1].plus = 'null';
            } else {
              this.array[i][1].plus = this.array[i][1].plus.toFixed(2) + ' m/s²';
            }
          }
          this.array[i][2] = 2;
          break;
        case('speed'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            const kmh = this.array[i][1];
            this.array[i][1] = (kmh / 1.609).toFixed(1) + ' mph';
          }
          this.array[i][2] = 1;
          break;
        case('springHeight'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            const mm = this.array[i][1];
            this.array[i][1] = (mm * 0.039370).toFixed(1) + ' Inch';
          }
          this.array[i][2] = 1;
          break;
        case('doorOpen'):
          if (this.array[i][1] === null) {
            this.array[i][1] = 'null';
          } else {
            this.door = this.array[i][1];
            if (this.door === 0) {
              this.array[i][1] = 'Close';
            }
            if (this.door === 1) {
              this.array[i][1] = 'Open';
            }
          }
          this.array[i][2] = 1;
          break;
      }
    }
  }

  private createLastState(channel: any) {
    this.lastState = {
      speed: channel.speed,
      brakeState: channel.brakeState,
      springHeight: channel.springHeight,
      doorOpen: channel.doorOpen,
      temperature: channel.temperature,
      batteryLevel: channel.batteryLevel,
      networkSignalStrength: channel.networkSignalStrength,
      accelerometerX: channel.accelerometerX,
      accelerometerY: channel.accelerometerY,
      accelerometerZ: channel.accelerometerZ,
    };
    for (const key in this.lastState) {
      if (this.lastState[key] === undefined) {
        delete this.lastState[key];
      }
    }
  }

  private formatDate(date: Date) {
    this.formattedDate = this.appendLeadingZeroes(date.getUTCHours()) + ':' +
    this.appendLeadingZeroes(date.getUTCMinutes()) + ':' +
    this.appendLeadingZeroes(date.getUTCSeconds()) + ' ' +
    this.appendLeadingZeroes(date.getUTCDate()) + '-' +
    this.appendLeadingZeroes(date.getUTCMonth() + 1) + '-' +
    date.getUTCFullYear();
  }

  private appendLeadingZeroes(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
