import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DeviceDashboardComponent } from './components/device-dashboard/device-dashboard.component';
import { MsalGuard } from '@azure/msal-angular';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard] },
      { path: 'dashboard/device', component: DeviceDashboardComponent, canActivate: [MsalGuard], data: {key: 'device'} }
    ])
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
