import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    LoginRoutingModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent]
})

export class LoginModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootLoginModule,
      providers: []
    };
  }
}

@NgModule({
  imports: [
    LoginModule
  ],
})
export class RootLoginModule {}
