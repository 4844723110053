export const links: Array<any> = [
  {
    id: 'common',
    name: 'Common',
    icon: 'settings',
    items: [
      {id: 'dashboard', name: 'DASHBOARD', link: 'dashboard', icon: 'home'},
      {id: 'devices', name: 'DEVICES', link: 'devices', icon: 'router'},
      {id: 'templates', name: 'DEVICE_TEMPLATES', link: 'templates', icon: 'settings_input_component'},
      // {id: 'map', name: 'Map', link: 'map', icon: 'map'},
      // {id: 'locations', name: 'Locations', link: 'locations', icon: 'map'}
    ]
  }
];
