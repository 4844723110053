import { Injectable } from '@angular/core';

import * as atlas from 'azure-maps-control';

/** A class for smoothly animating a line as it grows. */
@Injectable()
export class Snakeline {

  map: atlas.Map;
  path: atlas.data.LineString;
  duration: number;
  frameRate = 30;
  shape: atlas.Shape;
  positions: atlas.data.Position[];
  dataSource: atlas.source.DataSource;
  lineLayer: atlas.layer.LineLayer;
  intervalId: any;

  /**
   * A class for smoothly animating a line as it grows.
   * @param map A map instance to attach the snakeline to.
   * @param path A line path to animate over.
   * @param lineOptions Options specifying how the line should be rendered.
   * @param duration The duration in milliseconds to play the animation.
   * @param autoPlay Specifies if the animation should play immediately.
   * @param beforeLayer The layer or id of the layer to insert the new snake line layer before.
   */
  constructor(
    map: atlas.Map,
    path: atlas.data.LineString,
    lineOptions: atlas.LineLayerOptions,
    duration?: number,
    autoPlay?: boolean,
    beforeLayer?: atlas.layer.Layer | string
  ) {
    this.map = map;
    this.path = path;
    this.duration = duration || 1000;
    this.shape = null;

    this.dataSource = new atlas.source.DataSource();
    this.map.sources.add(this.dataSource);

    this.lineLayer = new atlas.layer.LineLayer(this.dataSource, 'historical', lineOptions);

    // Add a symbol layer for rendering the arrow along the line.
    const arrowLayer =  new atlas.layer.SymbolLayer(this.dataSource, 'historicalArrow', {
      lineSpacing: 100,
      placement: 'line',
      iconOptions: {
        image: 'arrow-icon',
        allowOverlap: true,
        anchor: 'center',
        size: 0.6
      }
    });

    this.map.layers.add([this.lineLayer, arrowLayer], beforeLayer);

    if (autoPlay) {
      this.positions = this._preparePolyline();
      this._plot();
    }
  }

  /** Plots the snakeline. */
  _plot(): void {
    this.dataSource.clear();

    const coordinatesPerFrame = Math.ceil(this.positions.length / (this.duration / this.frameRate));

    const points = [];
    let shapeAdded = false;

    this.intervalId = setInterval(() => {
      if (this.positions.length > 0) {
        let verticesInFrame;
        if (coordinatesPerFrame < this.positions.length) {
          verticesInFrame = this.positions.splice(0, coordinatesPerFrame);
        } else {
          verticesInFrame = this.positions.splice(0);
        }
        points.push(...verticesInFrame);
        if (points.length > 1) {
          if (!shapeAdded) {
            this.shape = new atlas.Shape(new atlas.data.LineString(points));
            this.dataSource.add(this.shape);
            shapeAdded = true;
          } else {
            this.shape.setCoordinates(points);
          }
        }
      } else {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }, this.frameRate);
  }

  /**
   * Calculate rendering points along the polyline
   * @returns An array of positions that make up the snake line.
   */
  _preparePolyline(): atlas.data.Position[] {
    const snakeLocations = [];
    const totalNoOfFrames = Math.ceil(this.duration / this.frameRate);
    const distance = atlas.math.getLengthOfPath(this.path);
    const frameDistance = Math.ceil(distance / totalNoOfFrames);

    for (let i = 0; i < this.path.coordinates.length - 1; i++) {
      snakeLocations.push(this.path.coordinates[i]);
      const from = this.path.coordinates[i];
      const to = this.path.coordinates[i + 1];
      const segmentLength = atlas.math.getDistanceTo(from, to);

      const newLocations = Math.floor(segmentLength / frameDistance);
      for (let j = 1; j < newLocations; j++) {
        const fraction = (frameDistance * j) / segmentLength;
        const intersectedLocation = atlas.math.interpolate(from, to, fraction);
        snakeLocations.push(intersectedLocation);
      }
    }
    // add the last coordinate
    snakeLocations.push(this.path.coordinates[this.path.coordinates.length - 1]);
    return snakeLocations;
  }

  /**
   * Replays the snakeline.
   * @param duration The duration in milliseconds to play the snakeline animation.
   */
  replay(duration?: number): void {
    if (duration) {
      this.duration = duration;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.positions = this._preparePolyline();

    if (this.shape) {
      this.shape.setCoordinates([]);
    }

    this._plot();
  }

  clear(): void {
    this.dataSource.clear();
  }

  addRoutePoints(startPosition: any, endPosition?: any): void {
    // Add a layer for rendering point data.
    this.map.layers.add(new atlas.layer.SymbolLayer(this.dataSource, null, {
      iconOptions: {
        image: ['get', 'iconImage'],
        allowOverlap: true,
        ignorePlacement: true
      },
      textOptions: {
        textField: ['get', 'title'],
        offset: [0, 1]
      },
      filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
    }));

    const startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
      title: 'Start point',
      iconImage: 'pin-blue'
    });

    const endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
      title: 'End point',
      iconImage: 'pin-red'
    });

    // Add the data to the data source.
    this.dataSource.add([startPoint, endPoint]);

    // Fit the map window to the bounding box defined by the start and end positions.
    // this.map.setCamera({
    //   bounds: atlas.data.BoundingBox.fromPositions([startPosition, endPosition]),
    //   padding: 50
    // });
  }

}
