import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { Device } from './models/device';
import { DataService } from '@app/services/data.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { MatPaginator } from '@angular/material';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('InventoryDataSource');

export class DevicesDataSource implements DataSource<Device> {

  private dataSubject = new BehaviorSubject<Device[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataChange: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
  get data(): Device[] { return this.dataChange.value; }
  public items: Device[];
  public total: number;

  constructor(
    private service: DataService
  ) {}

    connect(collectionViewer: CollectionViewer): Observable<Device[]> {
      return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
      this.dataSubject.complete();
      this.loadingSubject.complete();
    }

    loadItems(query: any, paginator: MatPaginator) {
      query.pageNumber = paginator.pageIndex + 1;
      query.pageSize = paginator.pageSize || 10;
      this.loadingSubject.next(true);
      this.service
        .getDevices(query)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(response => {
          this.items = response.data;
          log.debug(this.items);
          this.total = response.total;
          paginator.length = response.total;
          return this.dataSubject.next(response.data);
        });
    }
}
