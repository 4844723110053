import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { DataService } from '@app/services/data.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { MatPaginator } from '@angular/material';

// logger //
import { Logger } from '@app/services/logger.service';
const log = new Logger('ActionsDatasource');

export class ActionsDatasource implements DataSource<any> {

  private dataSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }
  public items: any[];

  constructor(
    private service: DataService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  getActions(id: any) {
    this.loadingSubject.next(true);
    this.service
      .getTemplateaActions(id)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(response => {
        log.debug(response.data);
        this.items = response.data;
        return this.dataSubject.next(response.data);
      });
  }
}
