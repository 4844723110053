import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone, ChangeDetectorRef , ViewChild, OnChanges, ElementRef, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material'
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subject, BehaviorSubject,  Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, FormArray } from '@angular/forms';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('DevicesComponent');

// services
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/modules/store/app.reducers';
import { DataService } from '@app/services/data.service';
import { TemplateDataSource } from './template.datasource';
import { ActionsDatasource } from './actions.datasource';
import { icons } from '@app/services/icons';
import { UtilsService } from '@app/services/utils.service';
import { TemplatesDialogService } from '../../templates-dialog.service';

export interface Element {
  displayType: string;
  dataType: string;
  channelID: string;
  displayName: string;
  description: string;
  lowCritical: number;
  lowWarning: number;
  highWarning: number;
  highCritical: number;
}

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TemplateComponent implements OnInit, OnDestroy {

  activeRoute: any;
  templateId: number;
  @ViewChild('scrollable') private scrollable: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100];
  pageEvent: PageEvent;
  loading: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();

  // channels
  dataSource: TemplateDataSource | null;
  displayedColumns = [
    'channelID',
    'displayName',
    'statuses',
    'dataType',
    'description',
    'options'
  ];

  // actions
  actionsDataSource: ActionsDatasource | null;
  actionsDisplayedColumns = [
    'actionName',
    'channelId',
    'actionType',
    'level',
    'options'
  ];

  form: FormGroup;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private utilsService: UtilsService,
    public fb: FormBuilder,
    private templatesDialogService: TemplatesDialogService
  ) {

  }

  ngOnInit() {
    this.store.select(fromRoot.getRouterState)
      .takeUntil(this.destroy$)
      .subscribe((data: any) => {
        this.activeRoute = data;
        this.templateId = this.activeRoute.queryParams.id;
      });
    setTimeout(() => {
      this.getChannels();
      this.getActions();
    }, 100);
  }

  selectedTabChange($event) {
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  getChannels(): void {
    this.dataSource = new TemplateDataSource(this.dataService);
    const query: any = {id: this.templateId};
    this.dataSource.getChannels(query);
    this.cdRef.markForCheck();
  }

  // actions
  getActions(): void {
    this.actionsDataSource = new ActionsDatasource(this.dataService);
    this.actionsDataSource.getActions(this.templateId);
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openChannel(selectedChannel: any): void {
    this.templatesDialogService
      .openChannel(this.templateId, selectedChannel)
      .subscribe((data: any) => {
          log.debug(data);
          this.getChannels();
        }
      );
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.name === i2.name;
  }

  logForm(): void {
    log.debug(this.form);
  }

  deleteChannel(selectedChannel: any): void {

  }

  // actions
  openAction(selectedChannel?: any, selectedAction?: any): void {
    this.templatesDialogService
      .openAction(this.templateId, this.dataSource.items, selectedChannel, selectedAction)
      .subscribe((data: any) => {
          log.debug(data);
          this.getActions();
        }
      );
  }

  deleteAction(element: any): void {
    this.dataService.deleteAction(this.templateId, element.action.actionId)
      .takeUntil(this.destroy$)
      .subscribe(
        response => log.debug(response),
        error => log.error(error),
        () => {
          this.getActions();
        }
      );
  }

}
