import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {ControlContainer} from '@angular/forms';
// logger
import {Logger} from '@app/services/logger.service';
// libs
import {addDays, addMonths, addWeeks, addYears} from 'date-fns';

import {Subject} from 'rxjs';
import 'rxjs/add/operator/map';
import {DateTime} from './models/date-time';

const log = new Logger('DatetimeSettingsComponent');

@Component({
  selector: 'app-datetime-settings',
  templateUrl: './datetime-settings.component.html',
  styleUrls: ['./datetime-settings.component.scss']
})

export class DatetimeSettingsComponent implements AfterViewInit, OnDestroy {

  @Input() useStorage = true;
  @Input() minDate: any | null;
  @Input() maxDate: any | null;

  destroy$: Subject<boolean> = new Subject<boolean>();
  dateTime: DateTime = {};

  constructor(
    public controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef,
  ) {
    const localValue = localStorage.getItem('dateTime');
    const localPeriod = localStorage.getItem('period');
    this.dateTime.localValue = localValue ? JSON.parse(localValue) : undefined;
    this.dateTime.localPeriod = localPeriod ? localPeriod : undefined;
  }

  ngAfterViewInit() {
    this.setToday();
  }

  setToday(): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.TODAY';
    const date = addDays(new Date(), -1);
    this.setValue(date, new Date());
  }

  setWeek(): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.THISWEEK';
    const date = addWeeks(new Date(), -1);
    this.setValue(date, new Date());
  }

  setTwoWeeks(): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.LASTTWOWEEKS';
    const date = addWeeks(new Date(), -2);
    this.setValue(date, new Date());
  }

  setMonths(value: number): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.LASTMONTH';
    const date = addMonths(new Date(), value);
    this.setValue(date, new Date());
  }

  setYears(value: number): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.LASTYEAR';
    const date = addYears(new Date(), value);
    this.setValue(date, new Date());
  }

  setValue(startDate: any, endDate: any) {
    log.debug('setValue:', startDate, endDate);
    this.controlContainer.control.setValue({
      startDate: startDate,
      endDate: endDate
    });

    // this.controlContainer.control.markAsDirty();
    this.controlContainer.control.markAsTouched();
    this.cdRef.markForCheck();
  }

  saveDateTime(): void {
    this.dateTime.period = 'DATETIMEPICKER.RANGE.CUSTOMDATE';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
