// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ROBIOTIC-NODE
// export const environment = {
//   production: false,
//   serverUrl: 'https://robioticnode.azurewebsites.net',
//   apiUrl: 'https://robioticuniwebapi.azurewebsites.net',
//   consentScope: '87ff08d5-30c0-4965-a195-2ffa7c219cd6',
//   authority: 'common',
//   clientID: '2d30e701-3a97-4f0f-9759-87c21173f8f3',
//   atlasSubscriptionKey: '8G0S2ifxSbvHmdvrKUGZGnHrAnbMhXpvXblevOC6Wqw',
// };


// TTX DEV
// export const environment = {
//   production: false,
//   serverUrl: 'https://ttx.azurewebsites.net',
//   apiUrl: 'https://uniwebapiadoptions.azurewebsites.net',
//   consentScope: '45278389-db33-49e6-9269-518426e4efb9',
//   authority: 'c84ea006-2574-4999-ab24-05c6d26ff55e',
//   clientID: '0ce30772-30aa-4aec-884c-185223f03c00',
// };

// TTX PROD
export const environment = {
  production: false,
  serverUrl: 'https://ttx.azurewebsites.net',
  apiUrl: 'https://robioticttxwebapi.azurewebsites.net',
  consentScope: 'b42339d2-4dfa-4e49-bb0e-c19f720a8034',
  authority: '7f411a52-c9c6-4dad-ba7a-1d65d0a083a0',
  clientID: '970aec99-5320-4984-9c6a-553b5fd12d95',
  atlasSubscriptionKey: '8G0S2ifxSbvHmdvrKUGZGnHrAnbMhXpvXblevOC6Wqw',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
