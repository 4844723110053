import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, NgZone, ChangeDetectorRef , ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, PageEvent } from '@angular/material';
import { Subject, BehaviorSubject,  Observable, interval, Subscription, of, throwError  } from 'rxjs';
import { catchError, map, finalize, takeUntil, switchMap, takeWhile, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as atlas from 'azure-maps-control';
import { timer } from 'rxjs/observable/timer';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/modules/store/app.reducers';
import { environment } from '@env/environment';

// logger
import { Logger } from '@app/services/logger.service';
const log = new Logger('DevicesComponent');

// services
import { DataService } from '@app/services/data.service';
import { DashboardDatasource } from './dashboard.datasource';
import { DialogService } from '@app/modules/dialog/dialog.service';
import { UtilsService } from '@app/services/utils.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  dataSource: DashboardDatasource | null;
  displayedColumns = [
    'deviceName',
    'location',
    'timestamp',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100];
  pageEvent: PageEvent;
  loading: boolean;
  showSideNav = true;

  // default query
  query: any = {};

  // atlas
  map: any;
  subscriptionKey: any = 'subscriptionKey';
  key = environment.atlasSubscriptionKey;
  mapDatasource: any;
  selectedLocation: any;
  pin: atlas.Shape = null;
  mapCenterPosition = [-110, 50];
  delay = 10; // ms
  i = 0;

  polling: any;
  initDataLoaded = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogService: DialogService,
    public utilsService: UtilsService,
    private store: Store<fromRoot.State>,
  ) {
    this.mapDatasource = new atlas.source.DataSource();
  }

  ngOnInit() {
    this.initMap();
    // setup pooling
    this.polling = setInterval(() => {
      this.mapDatasource.clear();
      this.dataSource.loadItems(this.query);
    }, 25000);

    this.store.select(fromRoot.getShowSidenav)
      .takeUntil(this.destroy$)
      .subscribe((data: any) => {
        setTimeout(() => {
          this.map.resize();
          setTimeout(() => {
            this.centerMapInBounds();
          }, 100);
        }, 800);
      });
  }

  initMap() {
    // init atlas
    // @ts-ignore
    this.map = new atlas.Map('mapContainer', {
      center: this.mapCenterPosition,
      language: 'en-US',
      zoom: 2,
      AnimationOptions: {
        duration: 2000,
        type: 'ease'
      },
      authOptions: {
        authType: this.subscriptionKey,
        subscriptionKey: this.key
      }
    });

    // Wait until the map resources are ready.
    this.map.events.add('ready', () => {
      setTimeout(() => {
        this.map.resize();
      }, 1000);

      // Create a data source and add it to the map.
      this.mapDatasource = new atlas.source.DataSource(null, {
        // Tell the data source to cluster point data.
        cluster: true,
        // The radius in pixels to cluster points together.
        clusterRadius: 45,
        // The maximium zoom level in which clustering occurs.
        // If you zoom in more than this, all points are rendered as symbols.
        clusterMaxZoom: 15
      });
      this.map.sources.add(this.mapDatasource);

      // Create a bubble layer for rendering clustered data points.
      const clusterBubbleLayer = new atlas.layer.BubbleLayer(this.mapDatasource, null, {
        // Scale the size of the clustered bubble based on the number of points inthe cluster.
        radius: [
          'step',
          ['get', 'point_count'],
          20,         // Default of 20 pixel radius.
          100, 30,    // If point_count >= 100, radius is 30 pixels.
          750, 40     // If point_count >= 750, radius is 40 pixels.
        ],

        // Change the color of the cluster based on the value on the point_cluster property of the cluster.
        color: [
          'step',
          ['get', 'point_count'],
          'rgba(0,255,0,0.8)',            // Default to green.
          100, 'rgba(255,255,0,0.8)',     // If the point_count >= 100, color is yellow.
          750, 'rgba(255,0,0,0.8)'        // If the point_count >= 100, color is red.
        ],
        strokeWidth: 0,
        filter: ['has', 'point_count'] // Only rendered data points which have a point_count property, which clusters do.
      });

      // Add a click event to the layer so we can zoom in when a user clicks a cluster.
      this.map.events.add('click', [clusterBubbleLayer], this.clusterClicked.bind(this));
      // Add mouse events to change the mouse cursor when hovering over a cluster.
      this.map.events.add('mouseenter', clusterBubbleLayer, () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.events.add('mouseleave', clusterBubbleLayer, () => {
        this.map.getCanvas().style.cursor = '';
      });

      // Add a layer for rendering point data as symbols.
      const locationsLayer = new atlas.layer.SymbolLayer(this.mapDatasource, 'locations', {
        iconOptions: {
          // Pass in the id of the custom icon that was loaded into the map resources.
          image: 'pin-round-darkblue',
          allowOverlap: true,
        },
        textOptions: {
          // Convert the Speed property of each feature into a string and concatenate "°km/h".
          textField: ['concat', ['to-string', ['get', 'installationPointDisplayName']]],
          // Offset the text so that it appears on top of the icon.
          offset: [0, 1],
          allowOverlap: true,
        },
        filter: ['!', ['has', 'point_count']] // Filter out clustered points from this layer.
      });

      // this.map.layers.add(clusterBubbleLayer, locationsLayer);

      // Add the clusterBubbleLayer and two additional layers to the map.
      this.map.layers.add([
        clusterBubbleLayer,
        // Create a symbol layer to render the count of locations in a cluster.
        new atlas.layer.SymbolLayer(this.mapDatasource, null, {
          iconOptions: {
            image: 'none'
          },
          textOptions: {
            textField: ['get' , 'point_count_abbreviated'],
            offset: [0, 0.4]
          }
        }),
        locationsLayer
      ]);

      this.map.events.add('click', [locationsLayer], this.featureClicked.bind(this));
      /* Construct a zoom control*/
      const zoomControl = new atlas.control.ZoomControl();
      this.map.controls.add(zoomControl, {});
      // initial load
      this.listItems();
    });
  }

  clusterClicked(e: any): void {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      // Get the clustered point from the event.
      const cluster = e.shapes[0];
      // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      this.mapDatasource.getClusterExpansionZoom(cluster.properties.cluster_id).then((zoom) => {
        // Update the map camera to be centered over the cluster.
        this.map.setCamera({
          center: cluster.geometry.coordinates,
          zoom: zoom,
          type: 'ease',
          duration: 200
        });
      });
    }
  }

  setMapStyle(style: string): void {
    this.map.setStyle({style: style});
    this.map.resize();
  }

  clearDataSource(): void {
    this.mapDatasource.clear();
  }

  logDataSource(): void {
    log.debug('#### this.mapDatasource', this.mapDatasource);
  }

  featureClicked(e: any): void {
    this.selectedLocation = e.shapes[0].properties;
    log.debug(`###this.selectedLocation`, this.selectedLocation);
    // this.dialogService
    //   .openWagon(_shape);
  }

  listItems(): void {
    this.dataSource = new DashboardDatasource(this.dataService);
    this.dataSource.loadItems(this.query);
    this.dataSource.loading$
      .pipe(
        takeUntil(this.destroy$)
      )
      // .take(1)
      .subscribe(data => {
          if (this.dataSource.items) {
            this.cdRef.markForCheck();
            this.dataSource.items
              .filter(item => item.longitude &&  item.latitude)
              .forEach(item => {
              const position = [item.longitude, item.latitude];
              const future = new atlas.data.Feature(new atlas.data.Point(position), {
                id: item.installationPointId,
                speed: 60,
                timeStamp: item.timeStamp,
                latitude: item.latitude,
                longitude: item.longitude,
                hardwareId: item.hardwareId,
                installationPointDisplayName: item.installationPointDisplayName
              }, item.installationPointId);
              this.mapDatasource.add(future);
            });
            // calculate bounds on initial view
            if (this.initDataLoaded === false && data === false) {
              this.initDataLoaded = true;
              this.centerMapInBounds();
            }
          }
        },
        error => {
          log.debug('Error:', error);
        });
  }

  centerMapInBounds(): void {
    const coordinates = [];
    this.dataSource.items
      .filter(item => item.longitude &&  item.latitude)
      .forEach(item => {
      const position = [item.longitude, item.latitude];
      coordinates.push(position);
    });
    const bounds = atlas.data.BoundingBox.fromPositions(coordinates);
    setTimeout(() => {
      this.map.setCamera({
        bounds: bounds,
        padding: {top: 50, bottom: 50, left: 50, right: 50}
      });
    }, 1000);
  }

  getStatusClass(element: any): string {
    return this.utilsService.getStatusClass(element);
  }

  toggle(): void {
    this.showSideNav = !this.showSideNav;
    setTimeout(() => {
      this.map.resize();
    }, 300);
  }

  showOnMap(element): void {
    log.debug('element:', element);
    const shape = this.mapDatasource.getShapeById(element.installationPointId);
    log.debug('shape:', shape);
    const center = shape.getCoordinates();
    // let offset;
    // if (this.map.getCanvas().width < 700) {
    //   offset = [0, -80];
    // }
    this.map.setCamera({
      center: center,
      // centerOffset: offset,
      zoom: 20,
      AnimationOptions: {
        duration: 2000,
        type: 'ease'
      },
    });
  }

  ngOnDestroy() {
    clearInterval(this.polling);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
