import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../modules/pipes/pipes.module';
import { SharedModule } from '../../modules/shared/shared.module';
// services
import { DialogService } from './dialog.service';

// components
import { ConfirmationComponent } from './confirmation/confirmationDialog.component';
import { EventFixErrorDialogComponent } from './event-fix-error/eventFixErrorDialog.component';

import {
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatCardModule,
  MatIconModule,
  MatDividerModule,
  MatGridListModule,
  MatListModule,
  MatToolbarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
} from '@angular/material';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatListModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    ConfirmationComponent,
    EventFixErrorDialogComponent
  ],
  exports: [
    ConfirmationComponent,
    EventFixErrorDialogComponent
  ],
  entryComponents: [
    ConfirmationComponent,
    EventFixErrorDialogComponent
  ],
  providers: [
    DialogService
  ]
})
export class DialogModule { }
