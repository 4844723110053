import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';
// libs
import * as FileSaver from 'file-saver';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// services
import {ActionsSubject, Store} from '@ngrx/store';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';
import {catchError, finalize, takeUntil} from 'rxjs/operators';
import {getTime, } from 'date-fns';

import * as atlas from 'azure-maps-control';
// logger
import {Logger} from '@app/services/logger.service';
// classes
import {Snakeline} from './snakeline.class';
import {Point} from './point.class';
import * as fromRoot from '@app/modules/store/app.reducers';
import {DataService} from '@app/services/data.service';
import {UtilsService} from '@app/services/utils.service';
import {DialogService} from '@app/modules/dialog/dialog.service';
import {MatTableDataSource} from '@angular/material/table';
import {ValidationService} from '@app/services/validation.service';
import {of} from 'rxjs/observable/of';
import {ChartComponent} from './states/chart/chart.component';

const log = new Logger('DevicesComponent');

@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html',
  styleUrls: ['./device-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DeviceDashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('chartsWrapper') chartsWrapper: ElementRef;
  @ViewChild(ChartComponent) chart;

  activeRoute: any;
  loading: boolean;
  chartLoading: boolean;
  isReady = false;
  isReadyLayout = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private deviceLocationSub: Subscription;
  device: any;
  deviceId: any;
  mode = 'live';
  dataPeriod: any = 'day';
  dateFrom: any;
  dateTo: any;
  channels: any[];

  // form options
  form: FormGroup;

  // pooling options
  updateRateDefault = 5000;
  polling: any;
  lastUpdate: number;
  lastUpdatePosition: any;

  showSideNav = true;
  dataSource = new MatTableDataSource<any>();
  displayedColumns = [
    'lat',
    'lan',
    'timestamp',
  ];

  // atlas
  subscriptionKey: any = 'subscriptionKey';
  key = environment.atlasSubscriptionKey;
  map: atlas.Map;
  snakeLine: any;
  path: any[] = [];
  point: any;
  noValidCoordinates = false;
  scrollMessage = false;
  coordinates: any[] = [];
  controls: any[] = [];
  deviceTypeArray: any[] = [];
  queryForDevicesList: any = {
    instPointId: ''
  };

  isReportLoading: boolean;
  installationPointTypeId: number;
  deviceType: any;
  private listOfDevicesSub: Subscription;

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private utilsService: UtilsService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private actionsSubject: ActionsSubject,
    private validationService: ValidationService,
    public fb: FormBuilder,
    private http: HttpClient,
  ) {
    // initialize form controls
    this.form = this.fb.group({
      dateTime: this.fb.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
      }, {
        validator: validationService.dateLessThan('startDate', 'endDate')
      }),
    });
  }

  get dateTime(): any {
    return this.form.get('dateTime');
  }

  ngOnInit() {
    this.store.select(fromRoot.getRouterState)
      .takeUntil(this.destroy$)
      .subscribe((data: any) => {
        this.activeRoute = data;
        this.deviceId = this.activeRoute.queryParams.id;
        // wait for open sidenav
        this.initMap();
        this.isReadyLayout = true;
        // setup pooling
        this.polling = setInterval(() => {
          this.getDeviceLocations(1);
        }, 25000);
      });

    // catch date changes
    this.dateTime.valueChanges
      .debounceTime(200)
      .distinctUntilChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.clearLayers();
        this.dateFrom = getTime(value.startDate);
        this.dateTo = getTime(value.endDate);
        this.getDeviceLocations(undefined, this.dateFrom, this.dateTo);
        log.debug('load', this.chartLoading);
      });

    this.store.select(fromRoot.getShowSidenav)
      .takeUntil(this.destroy$)
      .subscribe((data: any) => {
        setTimeout(() => {
          this.map.resize();
        }, 800);
      });
  }

  public onModeChange(val: string) {
    this.mode = val;
    this.clearLayers();
    this.noValidCoordinates = false;
    this.map.resize();
    setTimeout(() => {
      if (val === 'historical') {
        clearInterval(this.polling);
      } else {
        this.getDeviceLocations(1);
        this.polling = setInterval(() => {
          this.getDeviceLocations(1);
        }, 25000);
      }
      this.cdRef.markForCheck();
    }, 100);
  }

  ngAfterViewInit() {
    this.getListOfDevices(this.queryForDevicesList, this.deviceId);
  }

  // locations data
  public getDeviceLocations(top?: number, dateFrom?: any, dateTo?: any) {
    if (this.deviceId) {
      this.coordinates = [];
      this.loading = true;
      const query: any = {instPointId: this.deviceId};
      if (top) {
        query.top = top;
      }
      if (dateFrom) {
        query.dateFrom = dateFrom;
      }
      if (dateTo) {
        query.dateTo = dateTo;
      }
      // get locations
      // log.debug('query:', query);
        this.deviceLocationSub = this.dataService.getDeviceLocations(query)
          // .takeUntil(this.destroy$)
          .subscribe(
            response => {
              // response.data = response.data.filter(item => item.coordinates[0]);
              if (response.data.length) {
                this.noValidCoordinates = true;
                // live data
                if (this.mode === 'live') {
                  const item = response.data[0];
                  const position = [item.coordinates[1], item.coordinates[0]];
                  if (item.coordinates[0] && item.coordinates[1]) {
                    this.noValidCoordinates = false;
                    this.coordinates.push(position);
                    const point = {
                      id: item.id,
                      position: position,
                      timeStamp: item.timeStamp,
                      deviceDescription: item.deviceDescription,
                      installationPointDisplayName: item.installationPointDisplayName
                    };
                    this.point = point;
                    setTimeout(() => {
                      this.cdRef.markForCheck();
                      this.replayPointAnimation(point);
                    }, 100);
                  } else if (this.device.lastValidData && this.device.lastValidData.coordinates && this.device.lastValidData.coordinates[0]) {
                    this.noValidCoordinates = false;
                    const lastValidLocation = [this.device.lastValidData.coordinates[1], this.device.lastValidData.coordinates[0]];
                    const point = {
                      id: item.id,
                      position: lastValidLocation,
                      timeStamp: item.timeStamp,
                      deviceDescription: item.deviceDescription,
                      installationPointDisplayName: item.installationPointDisplayName
                    };
                    setTimeout(() => {
                      this.cdRef.markForCheck();
                      this.replayPointAnimation(point);
                    }, 100);
                  }
                  this.lastUpdatePosition = position;
                  this.lastUpdate = item.timeStamp;
                  // historical data
                } else if (this.mode === 'historical') {
                  this.dataSource.data = [...this.dataSource.data, ...response.data];
                  response.data.forEach(item => {
                    if (item.coordinates[0] && item.coordinates[1]) {
                      const position = [item.coordinates[1], item.coordinates[0]];
                      this.coordinates.unshift(position);
                      this.path.unshift(position);
                      this.noValidCoordinates = false;
                    }
                  });
                  // center map position by first point
                  // const centerPoint = [response.data.slice(-1)[0].coordinates[1],  response.data.slice(-1)[0].coordinates[0]];
                  // this.map.setCamera({center: centerPoint, zoom: 9, AnimationOptions: {duration: 2000, type: 'ease'}});
                  // draw line
                  setTimeout(() => {
                    this.replayRouteAnimation();
                  }, 100);
                  // center map
                  const bounds = atlas.data.BoundingBox.fromPositions(this.coordinates);
                  setTimeout(() => {
                    this.map.setCamera({
                      bounds: bounds,
                      padding: {top: 50, bottom: 50, left: 50, right: 50}
                    });
                    // draw start-end-points
                    const startPoint = this.coordinates[0];
                    const endPoint = this.coordinates.slice(-1).pop();
                    // log.debug('endPoint', startPoint, endPoint);
                    this.snakeLine.addRoutePoints(startPoint, endPoint);
                  }, 1000);
                }
              } else {
                this.noValidCoordinates = true;
              }
              // this.map.resize();
              this.loading = false;
              this.limitScrollWheelZoom();
              setTimeout(() => {
                this.cdRef.markForCheck();
              }, 500);
            },
            error => log.error(error),
            () => this.loading = false
          );
    }
  }

  private getListOfDevices(query, instPointId: any) {
    query.instPointId = instPointId;
    this.listOfDevicesSub = this.dataService
      .getListOfDevices(query)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          log.debug('getListOfDevices ', this.deviceTypeArray);
          this.cdRef.markForCheck();
        })
      )
      .subscribe(response => {
        log.debug('device data: ', response);
        this.device = response;
        this.deviceTypeArray = response.hardwareModels;
        this.installationPointTypeId = response.installationPointTypeId;
        this.deviceType = response.installationPointTypeId;
      });
  }

  public getDeviceData() {
    this.dataService.getDevice({
      instPointId: this.activeRoute.queryParams.id
    })
      .takeUntil(this.destroy$)
      .subscribe(
        response => {
          log.debug('device:', response);
          this.device = response;
          this.cdRef.markForCheck();
        },
        error => log.error(error),
        () => {
          this.loading = false;
        }
      );
  }

  // atlas
  initMap() {
    // init atlas
    // @ts-ignore
    this.map = new atlas.Map('mapContainer', {
      center: [-121.69281, 47.019588],
      language: 'en-US',
      zoom: 12,
      view: 'Auto',
      authOptions: {
        authType: this.subscriptionKey,
        subscriptionKey: this.key
      }
    });
    // Wait until the map resources are ready.
    this.map.events.add('ready', () => {
      // this.map.resize();
      this.map.imageSprite.add('arrow-icon', '../../../assets/purpleArrowRight.png');
      setTimeout(() => {
        this.map.resize();
        /* Construct a zoom control*/
        const zoomControl = new atlas.control.ZoomControl();
        this.map.controls.add(zoomControl, {});
        // load data from server
        this.getDeviceLocations(1);
      }, 300);
    });
  }

  replayPointAnimation(point: any): void {
    this.point = new Point(this.map, point, 'labels');
  }

  replayRouteAnimation(): void {
    const lineString = new atlas.data.LineString(this.path);
    const lineOptions = {
      strokeColor: 'DarkOrchid',
      strokeWidth: 3
    };
    // Snakeline module takes, map, the linestring, line options,
    // animations duration and a boolean indicating if it should auto play and a layer to display the line before.
    this.snakeLine = new Snakeline(this.map, lineString, lineOptions, 3000, false, 'labels');
    this.snakeLine.replay(3000);
  }

  clearLayers(): void {
    this.path = [];
    this.dataSource.data = [];
    if (this.snakeLine) {
      this.snakeLine.clear();
    }
    if (this.point) {
      this.point.clear();
    }
  }

  setMapStyle(style: string): void {
    this.map.setStyle({style: style});
    this.map.resize();
  }

  logDataSource(): void {
    log.debug('#### this.mapDatasource', this.map);
  }

  toggle(): void {
    this.showSideNav = !this.showSideNav;
    setTimeout(() => {
      this.map.resize();
    }, 300);
  }

  centerMapInBounds(): void {
    setTimeout(() => {
      if (this.mode === 'live') {
        this.map.setCamera({
          center: this.lastUpdatePosition,
          zoom: 9,
          AnimationOptions: {duration: 2000, type: 'ease'}
        });
      } else {
        const bounds = atlas.data.BoundingBox.fromPositions(this.coordinates);
        this.map.setCamera({
          bounds: bounds,
          padding: {top: 50, bottom: 50, left: 50, right: 50}
        });
      }
    }, 100);
  }

  limitScrollWheelZoom(): void {
    this.map.setUserInteraction({scrollZoomInteraction: false});
    const showMsgDialog = () => {
      this.scrollMessage = true;
      this.cdRef.markForCheck();
      setTimeout(() => {
        this.scrollMessage = false;
        this.cdRef.markForCheck();
      }, 2000);
    };
    this.map.getMapContainer().addEventListener('mousewheel', (e: any) => {
      if (!e.altKey) {
        showMsgDialog();
      }
    });
    window.addEventListener('keydown', (e) => {
      if (e.altKey) {
        this.map.setUserInteraction({scrollZoomInteraction: true});
      }
    });
    window.addEventListener('keyup', (e) => {
      if (!e.altKey) {
        this.map.setUserInteraction({scrollZoomInteraction: false});
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.deviceLocationSub) {
      this.deviceLocationSub.unsubscribe();
    }
    if (this.listOfDevicesSub) {
      this.listOfDevicesSub.unsubscribe();
    }
  }

  generateReport(isMetric?: boolean) {
    this.isReportLoading = true;
    const url = `${environment.apiUrl}/api/reports/installationpoint`;
    const params = {
      instPointId: this.deviceId,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      isMetric: isMetric
    };
    const queryParams: HttpParams = this.utilsService.buildQueryParams(params);
    this.http.get(url, {params: queryParams})
      .subscribe((data: any) => {
        this.downloadReport(data.link);
        this.isReportLoading = false;
        this.cdRef.markForCheck();
      });
  }

  public getFile(path: string, filename: string): Observable<Blob> {
    return this.http
      .get(path, {
        responseType: 'blob'
      });
  }

  downloadReport(link: any): any {
    this.getFile(link, 'report.csv')
      .subscribe(
        fileData => {
          log.debug('#################', fileData);
          if (fileData) {
            FileSaver.saveAs(fileData, 'report.csv');
          }
        },
        error => log.debug(error),
        () => log.debug('complete'));
  }

}
