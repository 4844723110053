import { NgModule } from '@angular/core';
import { DateFormatPipe } from './dateFormatPipe';
import { SecurePipe } from './securePipe';

@NgModule({
    imports: [],
    declarations: [
      DateFormatPipe,
      SecurePipe
    ],
    exports: [
      DateFormatPipe,
      SecurePipe
    ]
})
export class PipesModule {
}
