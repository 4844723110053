import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MatDialog, MatDialogRef } from '@angular/material';

// components
import { ChannelComponent } from './components/channel/channel.component';
import { ChannelActionsComponent } from './components/channel-actions/channel-actions.component';

@Injectable()
export class TemplatesDialogService {
  constructor(
    private dialog: MatDialog
  ) {}

  // dialog methods
  openChannel(templateId: any, selectedChannel?: any): Observable<any> {
    const config = {
      disableClose: true,
      autoFocus: false,
      panelClass: 'modal-with-toolbar',
      width: 'auto',
      height: 'auto',
      minWidth: '900px',
      minHeight: 'auto',
      overflow: 'auto',
      position: { top: '150px'}
    };

    let dialogRef: MatDialogRef<ChannelComponent>;
    dialogRef = this.dialog.open(ChannelComponent, config);
    dialogRef.componentInstance.selectedChannel = selectedChannel;
    dialogRef.componentInstance.templateId = templateId;

    return dialogRef.afterClosed();
  }

  openAction(templateId: any, channels: any, selectedChannel?: any, selectedAction?: any): Observable<any> {
    const config = {
      disableClose: true,
      autoFocus: false,
      panelClass: 'modal-with-toolbar',
      width: 'auto',
      height: 'auto',
      minWidth: '400px',
      minHeight: 'auto',
      overflow: 'auto',
      position: { top: '150px'}
    };

    let dialogRef: MatDialogRef<ChannelActionsComponent>;
    dialogRef = this.dialog.open(ChannelActionsComponent, config);
    dialogRef.componentInstance.channels = channels;
    dialogRef.componentInstance.selectedChannel = selectedChannel;
    dialogRef.componentInstance.selectedAction = selectedAction;
    dialogRef.componentInstance.templateId = templateId;

    return dialogRef.afterClosed();
  }

}
