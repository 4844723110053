import { Injectable } from '@angular/core';

import * as atlas from 'azure-maps-control';

/** A class for smoothly animating a line as it grows. */
@Injectable()
export class Point {

  map: atlas.Map;
  dataSource: atlas.source.DataSource;
  locationsLayer: atlas.layer.SymbolLayer;
  point: any;

  constructor(
    map: atlas.Map,
    point: any,
    beforeLayer?: atlas.layer.Layer | string
  ) {
    this.map = map;
    this.point = point;
    this.dataSource = new atlas.source.DataSource();
    this.map.sources.add(this.dataSource);
    this.locationsLayer = new atlas.layer.SymbolLayer(this.dataSource, 'points', {
      iconOptions: {
        // Pass in the id of the custom icon that was loaded into the map resources.
        image: 'pin-round-darkblue',
        allowOverlap: true,
      },
      textOptions: {
        // Convert the Speed property of each feature into a string and concatenate "°km/h".
        textField: ['concat', ['to-string', ['get', 'freightCar']]],
        // Offset the text so that it appears on top of the icon.
        offset: [0, 1],
        allowOverlap: true,
      }
    });

    this.map.layers.add(this.locationsLayer, beforeLayer);
    this.addPoint();
  }

  addPoint(): void {
    this.dataSource.clear();
    // Create a point feature and add it to the data source.
    const future = new atlas.data.Feature(new atlas.data.Point(this.point.position),   {
      id: this.point.id,
      timeStamp: this.point.timeStamp,
      deviceDescription: this.point.deviceDescription,
      installationPointDisplayName: this.point.installationPointDisplayName,
    }, this.point.id);

    this.dataSource.add(future);
    this.map.setCamera({center: this.point.position, zoom: 9, AnimationOptions: {duration: 2000, type: 'ease'}});
  }

  clear(): void {
    this.dataSource.clear();
  }
}
