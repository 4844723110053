import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DevicesComponent } from './devices.component';
import { MsalGuard } from '@azure/msal-angular';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'devices', component: DevicesComponent, canActivate: [MsalGuard] }
    ])
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
