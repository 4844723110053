import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TemplatesComponent } from './templates.component';
import { TemplateComponent } from './components/template/template.component';
import { MsalGuard } from '@azure/msal-angular';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'templates', component: TemplatesComponent, canActivate: [MsalGuard] },
      { path: 'templates/template', component: TemplateComponent, canActivate: [MsalGuard], data: {key: 'template'} }
    ])
  ],
  exports: [RouterModule]
})
export class TemplatesRoutingModule { }
